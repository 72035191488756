import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RootState } from '@app/store/root-state';
import { Observable } from 'rxjs';
import { Message } from '@app/store/messages/message-state';
import { MessageStoreSelectors } from '@app/store/messages';
import { messagesConsumed } from '@app/store/messages/message-actions';
import {dialogOppen} from '@app/store/client-slices/dialog/dialog-store-selectors';

@Injectable({
  providedIn: 'root',
})
export class NotificationAreaFacadeService {
  isDialogOpen$: Observable<boolean> = this.store.pipe(select(dialogOppen));

  constructor(private store: Store<RootState>) {}

  getPageMessages(): Observable<Message> {
    return this.store.pipe(select(MessageStoreSelectors.getPageMessages));
  }

  getDialogMessages(): Observable<Message> {
    return this.store.pipe(select(MessageStoreSelectors.getDialogMessages));
  }

  hanteraKonsumerad(konsumeratMeddelandeId: number): void {
    this.store.dispatch(messagesConsumed({ payload: konsumeratMeddelandeId }));
  }

}
