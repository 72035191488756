import {createAction, props} from '@ngrx/store';
import {FenceResult} from '@app/rest-client/faxe/models/fence-result';
import {FenceResultPost} from '@app/rest-client/faxe/models/fence-result-post';

const prefix = '[FAXE-FENCE-RESULT]';

export const fetchAllFenceResult = createAction(
  `${prefix} - fetch all`,
  props<{ issuerStack: string[] }>()
);

export const getFenceResult = createAction(
  `${prefix} - get`,
  props<{ issuerStack: string[] }>()
);

export const addFenceResult = createAction(
  `${prefix} - add`,
  props<{ model: FenceResult; issuerStack: string[] }>()
);

export const addManyFenceResult = createAction(
  `${prefix} - add many`,
  props<{ models: FenceResult[]; issuerStack: string[] }>()
);

export const removeFenceResult = createAction(
  `${prefix} - remove`,
  props<{ fenceResultIdentifier: string; issuerStack: string[] }>()
);

export const postFenceResult = createAction(
  `${prefix} - post`,
  props<{ fenceResult: FenceResultPost[]; issuerStack: string[]; correlationId: string }>()
);

export const reloadFenceResult = createAction(
  `${prefix} - reload`,
  props<{ issuerStack: string[] }>()
);
