export enum Icons {
  add = 'add',
  arrowBack = 'arrow_back',
  aspectRatio = 'aspect_ratio',
  assignment = 'assignment',
  assignmentReturn = 'assignment_return',
  cancel = 'cancel',
  checkCircle = 'check_circle',
  close = 'close',
  create = 'create',
  copy = 'copy',
  done = 'done',
  errorOutline = 'error_outline',
  expandLess = 'expand_less',
  expandMore = 'expand_more',
  info = 'info',
  link = 'link',
  filter = 'filter',
  filterAlt = 'filter_alt',
  linkOff = 'link_off',
  lock = 'lock',
  lockOpen = 'lock_open',
  moreHoriz = 'more_horiz',
  person = 'person',
  personAdd = 'person_add',
  personAddDisabled = 'person_add_disabled',
  personAdded = 'person_added',
  pictureAsPdf = 'picture_as_pdf',
  refresh = 'refresh',
  reportProblem = 'report_problem',
  search = 'search',
  openInNew = 'open_in_new',
  mail = 'mail',
  send = 'send',
  pdf = 'pdf',
  pause = 'pause',
  expandCircleDown = 'expand_circle_down',
  expandCircleUp = 'expand_circle_up',
  timer = 'timer'
}
