import { createAction, props } from '@ngrx/store';
import {ErrorMessageCodeEnum} from '@app/utils/services/error-message/error-message-code.enum';

interface DialogPayload {
  navigate?: boolean;
  correlationId?: string;
  operationSuccess?: boolean;
  errorMessageCodeEnum?: ErrorMessageCodeEnum;
}

export const initDialog = createAction(
  '[MODULE-DIALOG-STORE] Initierar dialog-slice i store'
);

export const setDialogOppnad = createAction(
  '[MODULE-DIALOG-STORE] öppnar dialog'
);

export const setDialogStangd = createAction(
  '[MODULE-DIALOG-STORE] stänger dialog'
);

export const stangDialog = createAction(
  '[MODULE-DIALOG-STORE] Stäng dialogen',
  props<{ payload: DialogPayload }>()
);
export const operationMisslyckades = createAction(
  '[MODULE-DIALOG-STORE]  Operationen som utfördes i dialogen misslyckades',
  props<{ payload: DialogPayload }>()
);
