<form [formGroup]="formGroup">
  <mat-form-field class="select-option-chip">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      #selectOption
      [placeholder]="placeholder"
      multiple
      id="optionchip"
      formControlName="value"
      (selectionChange)="onChange() ? selectOption.toggle() : null"
      [compareWith]="compareFn"
      [required]="getRequired()"
    >
      <mat-select-trigger>
        <mat-chip-list>
          <mat-chip
            *ngFor="let item of formGroup.get('value').value"
            [selectable]="true"
            [removable]="true"
            (removed)="removeItem(item)"
            selected="true"
            color="custom"
          >
            {{ item.displayname }}
            <span *ngIf="item && item.highlight" class="highlight">
              {{ item.highlight }}</span
            >
            <mat-icon svgIcon="cancel" matChipRemove></mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>
      <mat-option
        *ngFor="let item of getDataSource()"
        [value]="item"
        [disabled]="item.disabled"
      >
        {{ item.displayname }}
        <span *ngIf="item && item.highlight" class="highlight">{{
          item.highlight
        }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
