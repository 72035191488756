import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn,} from '@angular/forms';
import {Subject} from 'rxjs';
import {Confirmed, DataItem} from '@app/components/shared/model/data-item';
import {KombinationstypEnum} from '@app/constants/kombinationstyp';
import {Keyboard} from '@app/constants/keyboard';
import {MatLegacyChipInputEvent} from '@angular/material/legacy-chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {isEmpty} from '@app/utils/functions/common-functions';


@Component({
  selector: 'faxe-chip-list',
  styleUrls: ['./chip-list.component.scss'],
  templateUrl: './chip-list.component.html',
})
export class ChipListComponent<T>
  implements OnInit, OnChanges, OnDestroy {
  @Input() heading: string;
  @Input() formCtrlName: string;
  @Input() errorType: boolean;
  @Input() enableInput = false;
  @Input() inputHeading = 'new...';
  @Input() confirmed: Confirmed<T>;
  @Input() preSelectedKeys: number[];
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() confirmToggleMode = false;
  @Input() itemClassDefault: false;
  @Input() inputModeType = 'numeric';
  @Output() itemSelectedEvent: EventEmitter<DataItem<T>> = new EventEmitter<DataItem<T>>();
  @Output() itemPreSelectedEvent: EventEmitter<DataItem<T>[]> =
    new EventEmitter<DataItem<T>[]>();
  @Output() itemUnSelectedEvent: EventEmitter<DataItem<T>> = new EventEmitter<DataItem<T>>();
  @Output() lastItemUnSelectedEvent: EventEmitter<DataItem<T>> =
    new EventEmitter<DataItem<T>>();
  @Output() itemLockedClickEvent: EventEmitter<Confirmed<T>> = new EventEmitter<Confirmed<T>>();
  items: DataItem<T>[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  formGroup: UntypedFormGroup = this.formBuilder.group({
    value: [null],
    newvalue: undefined,
  });
  selectedItems: DataItem<T>[] = [];
  localCreatedItem: DataItem<T>;
  private unsubscribe$ = new Subject<void>();
  private sync: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdref: ChangeDetectorRef
  ) {
  }

  @Input() set itemsSet(value: DataItem<T>[]) {
    if (this.localCreatedItem) {
      value.push(this.localCreatedItem);
    }

    this.items = value;

    if (this.preSelectedKeys) {
      this.setPreSelected(this.preSelectedKeys);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.parentFormGroup.removeControl(this.formCtrlName);
  }

  ngOnInit(): void {
    if (!this.notMandatory()) {
      this.formGroup.controls.value.setValidators(this.validera);
      this.formGroup.controls.value.updateValueAndValidity();
    }
    if (this.formCtrlName) {
      this.parentFormGroup.addControl(this.formCtrlName, this.formGroup);
    }

    this.sync = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hanteraPreselectedKeysChanges(changes);
    this.hanteraConfirmedChanges(changes);
    this.hanteraItemsChanges(changes);
    this.cdref.detectChanges();
  }

  onClick(item: DataItem<T>): void {
    if (this.sync) {
      return;
    }

    this.sync = true;

    if (!this.confirmToggleMode || (this.confirmed && this.confirmed.value)) {
      this.toggleSelected(item, true);
    } else if (!this.isSelected(item)) {
      this.itemSelectedEvent.emit(item);
    } else if (this.isSelected(item) && this.confirmToggleMode) {
      this.itemLockedClickEvent.emit({item} as Confirmed<T>);
    } else {
      this.sync = false;
    }
  }

  clear(): void {
    this.localCreatedItem = undefined;
    this.selectedItems = [];
    this.formGroup.reset();
    this.formGroup.markAsUntouched();
  }

  isSelected(item: DataItem<T>): boolean {
    return this.selectedItems.findIndex((i) => i.key === item.key) >= 0;
  }

  show(me: DataItem<T>): boolean {

    const othersAndICanExcludeOthers = this.selectedItems.find(
      (other) =>
        me.key !== other.key &&
        other.kombinationstypEnum === KombinationstypEnum.canNExcludeOthers
    );

    if (othersAndICanExcludeOthers) {
      return me.kombinationstypEnum === KombinationstypEnum.canNExcludeOthers;
    }

    const otherCan1AndExcludeOthers = this.selectedItems.find(
      (other) =>
        me.key !== other.key &&
        other.kombinationstypEnum === KombinationstypEnum.can1AndExcludeOthers
    );

    if (otherCan1AndExcludeOthers) {
      return false;
    }

    const exclusive = this.selectedItems.find(
      (other) =>
        me.key !== other.key &&
        me.kombinationstypEnum === KombinationstypEnum.can1of1 &&
        other.kombinationstypEnum === KombinationstypEnum.can1of1
    );


    const exclusiveOne = this.selectedItems.find(
      (other) =>
        me.key !== other.key &&
        other.kombinationstypEnum === KombinationstypEnum.must1of1 &&
        me.kombinationstypEnum === KombinationstypEnum.must1of1
    );

    const exclusiveTwo = this.selectedItems.find(
      (other) =>
        me.key !== other.key &&
        other.kombinationstypEnum === KombinationstypEnum.must1of2 &&
        me.kombinationstypEnum === KombinationstypEnum.must1of2
    );

    const exclusiveThree = this.selectedItems.find(
      (other) =>
        me.key !== other.key &&
        other.kombinationstypEnum === KombinationstypEnum.must1of3 &&
        me.kombinationstypEnum === KombinationstypEnum.must1of3
    );

    return !(exclusive || exclusiveOne || exclusiveTwo || exclusiveThree);
  }

  onKeyPress($event: KeyboardEvent, item: DataItem<T>): void {
    if ($event.code === Keyboard.space) {
      this.onClick(item);
    }
  }

  add($event: MatLegacyChipInputEvent) {
    this.addNew($event.value);
    $event.input.value = '';
  }

  addNew(value: string) {
    if (!value) {
      return;
    }

    this.localCreatedItem =
      {
        key: +value, sortOrder: 0, value: undefined, displayname: value,
        default: +value,
        kombinationstypEnum: KombinationstypEnum.must1of1
      };

    this.items.push(this.localCreatedItem);
    this.onClick(this.localCreatedItem);
  }

  onFocusout() {
    this.addNew(this.formGroup.value.newvalue);
    this.formGroup.controls.newvalue.patchValue(undefined);
  }

  private setPreSelected(keys: number[]) {
    if (!keys) {
      return;
    }
    const newSelected = this.filterNewSelected(keys);
    if (!newSelected) {
      return;
    }

    this.selectedItems = newSelected;
    this.patchFormGroup(this.selectedItems);
    this.itemPreSelectedEvent.emit(newSelected);
    this.sync = false;
  }

  private hanteraItemsChanges(changes: SimpleChanges): void {
    if (changes.items && this.items && this.items.length) {
      if (this.items.every((i) => i.sortOrder)) {
        this.items.sort((a, b) => a.sortOrder - b.sortOrder);
      }
      if (this.notMandatory()) {
        this.formGroup.controls.value.clearValidators();
      } else {
        this.formGroup.controls.value.setValidators(this.validera);
      }
      this.formGroup.controls.value.updateValueAndValidity();
      this.setPreSelected(this.preSelectedKeys);
    }
  }

  private hanteraConfirmedChanges(changes: SimpleChanges) {
    if (changes.confirmed && changes.confirmed.currentValue) {
      if (changes.confirmed.currentValue.value) {
        this.toggleSelected(changes.confirmed.currentValue.item, false);
      } else {
        this.sync = false;
      }
    }
  }

  private hanteraPreselectedKeysChanges(changes: SimpleChanges) {
    if (changes.preSelectedKeys && this.preSelectedKeys) {

      if (this.preSelectedKeys.length > 0 && !this.confirmToggleMode) {
        this.setPreSelected(this.preSelectedKeys);
      }
    }
  }

  private notMandatory(): boolean {
    return this.items?.every(
      (i) =>
        i.kombinationstypEnum === KombinationstypEnum.can ||
        i.kombinationstypEnum === KombinationstypEnum.can1of1
    );
  }


  private filterNewSelected(keys: number[]): DataItem<T>[] {
    if (!this.items) {
      return undefined;
    }
    const candidates = this.items.filter(
      (i) => keys.findIndex((k) => k === i.key) >= 0
    );
    if (!candidates.length) {
      return undefined;
    }

    const notSelected = candidates.filter(
      (c) => this.selectedItems.findIndex((i) => i.key === c.key) < 0
    );
    if (!notSelected.length) {
      return undefined;
    }

    return notSelected;
  }

  private patchFormGroup(items: DataItem<T>[]) {
    this.formGroup.controls.value.patchValue(items);
  }

  private toggleSelected(item: DataItem<T>, emitEvent: boolean) {
    this.confirmed = null;
    if (item && this.selectedItems.find((i) => i.key === item.key)) {
      this.removeSelected(item);
      this.patchFormGroup(this.selectedItems);
      if (emitEvent) {
        this.itemUnSelectedEvent.emit(item);
        if (isEmpty(this.selectedItems)) {
          this.lastItemUnSelectedEvent.emit(item);
        }
      }
    } else {
      this.selectedItems.push(item);
      this.patchFormGroup(this.selectedItems);
      if (emitEvent) {
        this.itemSelectedEvent.emit(item);
      }
    }
    this.sync = false;
  }

  private removeSelected(item: DataItem<T>) {
    this.selectedItems = this.selectedItems.filter((i) => i.key !== item.key);
  }

  private validera: ValidatorFn = (): ValidationErrors | null => {
    if (
      this.items?.find(
        (i) =>
          !this.selectedItems?.find(
            (s) => s.kombinationstypEnum === i.kombinationstypEnum
          )
      )
    ) {
      return {required: {}};
    }
  };


}
