import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ReloadRouteUtilityService} from '@app/utils/services/reload-route/reload-route-utility.service';
import {PageId} from '@app/constants/page-id';
import {PasswordService} from '@app/utils/services/password/password.service';

@Injectable({
  providedIn: 'root',
})
export class AppRouteGuardHome implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private reloadRouteUtilityService: ReloadRouteUtilityService,
    private passwordService: PasswordService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.reloadRouteUtilityService.storeCurrentUrlInSessionStorage(state.url);

    if(this.passwordService.isViewer()) {
        this.router.navigate([PageId.fenceResultAudience]);
      }

    return of(true);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.reloadRouteUtilityService.storeCurrentUrlInSessionStorage(state.url);
    return of(true);
  }

}
