import {createSelector, MemoizedSelector} from '@ngrx/store';
import {getRiderExtendedState} from '@app/store/faxe/faxe-selectors';
import {selectAllRider} from '@app/store/faxe/rider-extended/rider-extended.reducer';
import {RiderExtendedSignalR} from '@app/rest-client/faxe/models/rider-extended-signal-r';



// eslint-disable-next-line @typescript-eslint/ban-types
export const selectRiderAll: MemoizedSelector<object, RiderExtendedSignalR[]> = createSelector(
  getRiderExtendedState,
  selectAllRider
);

export const selectRiderExtended = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, RiderExtendedSignalR[]> =>
  createSelector(
    getRiderExtendedState,
    selectRiderAll,
    (state, all) => all
  );


export const selectRiderById = (riderId: string):
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, RiderExtendedSignalR> =>
  createSelector(
    getRiderExtendedState,
    selectRiderAll,
    (state, all) => all
        .find(x => x.rider.riderId === riderId)
  );

export const riderRecentlyOnTrack = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, RiderExtendedSignalR[]> =>
  createSelector(
    getRiderExtendedState,
    selectRiderAll,
    (state, all) => all
      .filter(x => x.rider.isReportable)
  );

export const riderOnTrack = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, RiderExtendedSignalR[]> =>
  createSelector(
    getRiderExtendedState,
    selectRiderAll,
    (state, all) => all
      .filter(x => x.rider.isOnTrack)
  );
