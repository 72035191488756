import {createSelector, MemoizedSelector} from '@ngrx/store';
import {getResultCodesState} from '@app/store/faxe/faxe-selectors';
import {ResultCode} from '@app/rest-client/faxe/models/result-code';
import {selectAllResultCode} from '@app/store/faxe/results-code/result-code.reducer';



// eslint-disable-next-line @typescript-eslint/ban-types
export const selectResultCodeAll: MemoizedSelector<object, ResultCode[]> = createSelector(
  getResultCodesState,
  selectAllResultCode
);

export const selectResultCode = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, ResultCode[]> =>
  createSelector(
    getResultCodesState,
    selectResultCodeAll,
    (state, all) => all
  );



