/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { RaceClass } from '../models/race-class';
import { KmlInfo } from '../models/kml-info';

@Injectable({
  providedIn: 'root',
})
export class RaceClassService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRaceClassByRaceId
   */
  static readonly GetRaceClassByRaceIdPath = '/race/{raceId}/classes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRaceClassByRaceId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRaceClassByRaceId$Response(params: {
    raceId: string;
  }): Observable<StrictHttpResponse<Array<RaceClass>>> {

    const rb = new RequestBuilder(this.rootUrl, RaceClassService.GetRaceClassByRaceIdPath, 'get');
    if (params) {
      rb.path('raceId', params.raceId, {"style":"simple"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RaceClass>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRaceClassByRaceId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRaceClassByRaceId(params: {
    raceId: string;
  }): Observable<Array<RaceClass>> {

    return this.getRaceClassByRaceId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RaceClass>>) => r.body as Array<RaceClass>)
    );
  }

  /**
   * Path part for operation getRaceClasses
   */
  static readonly GetRaceClassesPath = '/classes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRaceClasses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRaceClasses$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RaceClass>>> {

    const rb = new RequestBuilder(this.rootUrl, RaceClassService.GetRaceClassesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RaceClass>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRaceClasses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRaceClasses(params?: {
  }): Observable<Array<RaceClass>> {

    return this.getRaceClasses$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RaceClass>>) => r.body as Array<RaceClass>)
    );
  }

  /**
   * Path part for operation updateRaceClasses
   */
  static readonly UpdateRaceClassesPath = '/classes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRaceClasses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRaceClasses$Response(params: {
    raceClassId: number;
    body: Array<RaceClass>
  }): Observable<StrictHttpResponse<Array<RaceClass>>> {

    const rb = new RequestBuilder(this.rootUrl, RaceClassService.UpdateRaceClassesPath, 'put');
    if (params) {
      rb.query('raceClassId', params.raceClassId, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RaceClass>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRaceClasses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRaceClasses(params: {
    raceClassId: number;
    body: Array<RaceClass>
  }): Observable<Array<RaceClass>> {

    return this.updateRaceClasses$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RaceClass>>) => r.body as Array<RaceClass>)
    );
  }

  /**
   * Path part for operation postRaceClasses
   */
  static readonly PostRaceClassesPath = '/classes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postRaceClasses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postRaceClasses$Response(params: {
    body: Array<RaceClass>
  }): Observable<StrictHttpResponse<Array<RaceClass>>> {

    const rb = new RequestBuilder(this.rootUrl, RaceClassService.PostRaceClassesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RaceClass>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postRaceClasses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postRaceClasses(params: {
    body: Array<RaceClass>
  }): Observable<Array<RaceClass>> {

    return this.postRaceClasses$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RaceClass>>) => r.body as Array<RaceClass>)
    );
  }

  /**
   * Path part for operation deleteRaceClass
   */
  static readonly DeleteRaceClassPath = '/classes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRaceClass()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRaceClass$Response(params: {
    raceClassId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RaceClassService.DeleteRaceClassPath, 'delete');
    if (params) {
      rb.query('raceClassId', params.raceClassId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRaceClass$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRaceClass(params: {
    raceClassId: number;
  }): Observable<void> {

    return this.deleteRaceClass$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation synchronizeCoordinates
   */
  static readonly SynchronizeCoordinatesPath = '/classes/{RaceClassId}/synchCoordinates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `synchronizeCoordinates()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  synchronizeCoordinates$Response(params: {
    RaceClassId: number;
    body?: {
'file': Blob;
}
  }): Observable<StrictHttpResponse<KmlInfo>> {

    const rb = new RequestBuilder(this.rootUrl, RaceClassService.SynchronizeCoordinatesPath, 'post');
    if (params) {
      rb.path('RaceClassId', params.RaceClassId, {"style":"simple"});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KmlInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `synchronizeCoordinates$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  synchronizeCoordinates(params: {
    RaceClassId: number;
    body?: {
'file': Blob;
}
  }): Observable<KmlInfo> {

    return this.synchronizeCoordinates$Response(params).pipe(
      map((r: StrictHttpResponse<KmlInfo>) => r.body as KmlInfo)
    );
  }

}
