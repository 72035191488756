import { Injectable } from '@angular/core';
import {SessionService} from '@app/utils/services/session/session.service';
import {SessionKeys} from '@app/constants/session-keys';
import {AppConfigService} from '@app/utils/services/config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor() { }

  isViewer(): boolean {
   return !!AppConfigService.environmentConfigData?.viewers.find(v => v.url === window.location.hostname);
  }

  hasValidPassword(): boolean {
    return AppConfigService.environmentConfigData?.userKeyword?.toLowerCase() ===
    SessionService.get(SessionKeys.password) ||
      AppConfigService.environmentConfigData.adminKeyword?.toLowerCase() ===
      SessionService.get(SessionKeys.password);
  }

  setPassword(password: string): void {
      SessionService.set(SessionKeys.password, password.toLowerCase().trim());
  }

}
