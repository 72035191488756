import {createAction, props} from '@ngrx/store';
import {SignalRStatus} from '@app/store/client-slices/signal-r/model/signal-r-status';

const prefix = '[FAXE-SYS-SIGNAL-R]';



export const onSignalRStatus = createAction(
  `${prefix} - onSignalStatus`,
  props<{ model: SignalRStatus }>()
);


