import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'typeof'
})
export class TypeofPipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  transform(value: any): any {
    const type =  typeof value;
    if(Object.prototype.toString.call(value) === '[object Array]') {
      return 'array';
    }

    return type;
  }

}
