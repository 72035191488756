import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {AutofocusDirective} from '@app/components/shared/input-form/auto-focus-direktive';

@NgModule({
  declarations: [AutofocusDirective],
  imports: [CommonModule],
  exports: [AutofocusDirective],
})
export class AutoFocusDirectiveModule {}
