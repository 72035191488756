import {Directive, HostBinding, HostListener, Input, TemplateRef, ViewContainerRef,} from '@angular/core';

@Directive({
  selector: '[faxeExpandedTableRow]',
})
export class ExpandedTableRowDirective {
  row: any;
  private tRef: TemplateRef<any>;
  private opened: boolean;

  constructor(public vcRef: ViewContainerRef) {}

  @HostBinding('class.expanded')
  get expended(): boolean {
    return this.opened;
  }

  @Input()
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set detailRow(value: any) {
    if (value !== this.row) {
      this.row = value;
    }
  }

  @Input()
  set templateRef(value: TemplateRef<any>) {
    if (value !== this.tRef) {
      this.tRef = value;
    }
  }

  @HostListener('click')
  onClick(): void {
    this.toggle();
  }

  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onKeyDown(event): void {
    const keyCode = event.code.toLowerCase();
    if (keyCode !== 'space' && keyCode !== 'enter') {
      return;
    }
    this.toggle();
  }

  toggle(): void {
    if (this.opened) {
      this.vcRef.clear();
    } else {
      this.render();
    }
    this.opened = this.vcRef.length > 0;
  }

  private render(): void {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.tRef, { $implicit: this.row });
  }
}
