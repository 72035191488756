import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'faxe-collapisble-section',
  templateUrl: './collapisble-section.component.html',
  styleUrls: ['./collapisble-section.component.scss']
})
export class CollapisbleSectionComponent {

  @Input() rubrik: string;
  @Output() collapsToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() collapsed = true;
  expandIcon = 'expand_circle_down';

  @Input() set isCollapsed(value: boolean) {
    if (!value) {
      return;
    }
    this.collapsed = value;
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      this.expandIcon = 'expand_circle_down';
    } else {
      this.expandIcon = 'expand_circle_up';
    }
    this.collapsToggle.emit(this.collapsed);
  }

}
