import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HighlightPipe } from './highlight/highlight.pipe';
import {TypeofPipe} from '@app/components/utils/pipes/typeof.pipe';

@NgModule({
  declarations: [
    HighlightPipe,TypeofPipe
  ],
  imports: [CommonModule, RouterModule],
  providers: [DecimalPipe],
  exports: [
    HighlightPipe,
    TypeofPipe,
  ],
})
export class PipesModule {}
