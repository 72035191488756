import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonMenueComponent } from './button-menue.component';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';



@NgModule({
  declarations: [
    ButtonMenueComponent
  ],
  exports: [
    ButtonMenueComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class ButtonMenueModule { }
