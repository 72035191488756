import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Message } from '@app/store/messages/message-state';
import { NotificationMessageTypeIconsEnum } from './notification-message-type-icons.enum';

@Component({
  selector: 'faxe-notification-message',
  templateUrl: './notification-message.component.html',
})
export class NotificationMessageComponent implements AfterViewInit, OnDestroy {
  @Input() message: Message;
  @Output() removeMessage = new EventEmitter<Message>();
  @Output() renderedHeight = new EventEmitter<number>();

  private mcssClasses: string[] = ['notification-area'];

  constructor(private elementRef: ElementRef) {}

  get icon(): string {
    return NotificationMessageTypeIconsEnum[this.message.type] || '';
  }

  get cssClasses(): string {
    return [...this.mcssClasses, this.message.type.toLowerCase()]
      .join(' ')
      .trimEnd();
  }

  @Input() set cssClasses(cssClasses: string) {
    this.mcssClasses = [...this.mcssClasses, ...cssClasses.split(' ')];
  }

  ngAfterViewInit(): void {
    this.renderedHeight.emit(
      this.elementRef.nativeElement.getBoundingClientRect().height
    );
  }

  removeNotification(): void {
    this.removeMessage.emit(this.message);
  }

  ngOnDestroy(): void {
    this.renderedHeight.emit(0);
  }
}
