import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlDisplayComponent } from './html-display.component';

@NgModule({
  declarations: [HtmlDisplayComponent],
  imports: [CommonModule],
  exports: [HtmlDisplayComponent],
})
export class HtmlDisplayModule {}
