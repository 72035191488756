import { createAction, props } from '@ngrx/store';


export const applicationShowErrorMessage = createAction(
  '[APPLICATION] show error message',
  props<{ payload: { error: any; issuer: string } }>()
);
export const operationSuccess = createAction(
  '[APPLICATION] operation success',
  props<{
    payload: { operation: string; correlationId: string; issuer: string };
  }>()
);



