import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TableComponent} from './table.component';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule}
  from '@angular/material/legacy-paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import {PipesModule} from '@app/components/utils/pipes/pipes.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {
  ExpandedTableRowDirectiveModule
} from '@app/components/shared/table/expanded-table-row-directive/expanded-table-row-directive.module';
import {PaginatorIntlSwe} from '@app/components/utils/paginator/paginator-intl-swe';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatRippleModule} from "@angular/material/core";


@NgModule({
  declarations: [
    TableComponent
  ],
  exports: [
    TableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatIconModule,
    MatSortModule,
    PipesModule,
    MatButtonModule,
    ExpandedTableRowDirectiveModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatRippleModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: PaginatorIntlSwe },
  ],
})
export class TableModule { }
