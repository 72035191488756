<faxe-page-wrapper>
  <img  class="banner-img" src="./assets/images/faxe-cropped.png"/>
  <div style="margin-top: 30px;">


    <div style="position: absolute; bottom: 20px">
      <p>Förbättringsförslag till
        <a href="mailto:feedback@faxeit.se">faxeit</a>
        Tack för din medverkan!
      </p>

    </div>
  </div>
  <p></p>

</faxe-page-wrapper>





