import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {addFenceResult, addManyFenceResult, removeFenceResult} from '@app/store/faxe/fence-result/fence-result.actions';
import {FenceResult} from '@app/rest-client/faxe/models/fence-result';

export const identifier = (a: FenceResult): string => a.fenceResultIdentifier;

export const adapter: EntityAdapter<FenceResult> = createEntityAdapter<FenceResult>({
  selectId: identifier,
});

export type FenceResultEntityState = EntityState<FenceResult>;

export const initialState: FenceResultEntityState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(addFenceResult, (state, {model}) => adapter.addOne(model, state)),
  on(addManyFenceResult, (state, {models}) => adapter.addMany(models, state)),
  on(removeFenceResult, (state, {fenceResultIdentifier}) => adapter.removeOne(fenceResultIdentifier, state)),
);

export const fenceResultReducer: (state: (FenceResultEntityState | undefined), action: Action) =>
  EntityState<FenceResult> = (state: FenceResultEntityState | undefined, action: Action) =>
  reducer(state, action);

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllFenceResult = selectAll;


