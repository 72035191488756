import {createSelector, MemoizedSelector} from '@ngrx/store';
import {RaceClass} from '@app/rest-client/faxe/models/race-class';
import {getRaceClassState} from '@app/store/faxe/faxe-selectors';
import {selectAllRaceClass} from '@app/store/faxe/race-class/race-class.reducer';



// eslint-disable-next-line @typescript-eslint/ban-types
export const selectRaceClassAll: MemoizedSelector<object, RaceClass[]> = createSelector(
  getRaceClassState,
  selectAllRaceClass
);


export const selectRaceClass = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object,RaceClass[]> =>
  createSelector(
    getRaceClassState,
    selectRaceClassAll,
    (state, all) => all
  );


export const selectRaceClassById = (raceClassId: number):
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, RaceClass> =>
  createSelector(
    getRaceClassState,
    selectRaceClassAll,
    (state, all) => all
        .find(x => x.raceClassId === raceClassId)
  );
