import {
  initMessageStoreState,
  Message,
  MessageStoreState,
} from './message-state';
import {Action, createReducer, on} from '@ngrx/store';
import {
  message,
  messagesConsumed,
} from './message-actions';

export const messagesReducer = (
  state: MessageStoreState,
  action: Action
): MessageStoreState => featureReducer(!state ? initMessageStoreState : state, action);

const cloneWithId = (
  inputMessage: Message,
): Message => ({
  ...inputMessage,
  id: Math.floor(Math.random() * 1000000) + 1
});

const featureReducer = createReducer(
  initMessageStoreState,
  on(message, (state, action) => ({
    data: [
      ...state.data,
      cloneWithId(action.payload),
    ],
  })),
  on(messagesConsumed, (state, action) => {
    const nonConsumed = state.data.filter((m) => action.payload !== m.id);
    return {data: [...nonConsumed]};
  })
);

