import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {mergeMap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {operationMisslyckades, stangDialog} from './dialog-store-actions';
import {onOperationStatus} from '@app/store/client-slices/operation/operation.actions';
import {ErrorMessageCodeEnum} from '@app/utils/services/error-message/error-message-code.enum';
import {OperationStatusEnum} from '@app/store/client-slices/operation/model/operationstatusenum';

@Injectable()
export class DialogStoreEffects {

  operationSuccessEffect = createEffect(() =>
    this.actions.pipe(
      ofType(onOperationStatus),
      mergeMap((action) => of(stangDialog({
        payload: {
          correlationId: action.model.correlationId,
          operationSuccess: true
        }
      })))
    )
  );

  operationFailEffect = createEffect(() =>
    this.actions.pipe(
      ofType(onOperationStatus),
      mergeMap((action) => {

        if (action.model.status === OperationStatusEnum.ok) {
          return EMPTY;
        }
        return of(operationMisslyckades(
          {
            payload: {
              correlationId: action.model.correlationId,
              operationSuccess: false,
              errorMessageCodeEnum: ErrorMessageCodeEnum.i002
            }
          }
        ));
      })
    )
  );

  constructor(private actions: Actions) {
  }

}
