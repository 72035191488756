import { Injectable } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {setDialogOppnad, setDialogStangd, initDialog} from '@app/store/client-slices/dialog/dialog-store-actions';
import {getDialogStoreState} from '@app/store/client-slices/dialog/dialog-store-selectors';
import {Observable} from 'rxjs';
import {DialogStoreState} from '@app/store/client-slices/dialog/dialog-store-state';

@Injectable({ providedIn: 'root' })
export class DialogFacadeService {
  constructor(private store: Store<RootState>) {}

  hamtaDialogStoreStatus(): Observable<DialogStoreState> {
    return this.store.pipe(select(getDialogStoreState));
  }

  initDialog(): void {
    this.store.dispatch(initDialog());
  }

  closeDialog(): void {
    this.store.dispatch(setDialogStangd());
  }

  openDialog(): void {
    this.store.dispatch(setDialogOppnad());
  }
}
