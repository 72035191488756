import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren, ViewEncapsulation,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Table } from '@app/components/shared/table/model/table';
import { Column } from '@app/components/shared/table/model/column';
import { Action } from '@app/components/shared/table/model/action';
import { ActionsIds } from '@app/components/shared/table/action-ids';
import { Row } from '@app/components/shared/table/model/row';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ExpandedTableRowDirective } from '@app/components/shared/table/expanded-table-row-directive/expanded-table-row.directive';

declare type FlatRow<T> = T & { detailTable: Table<any>; highligt: string };

@Component({
  selector: 'faxe-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent<T> {
  @Output() inlineEditAction: EventEmitter<Action<T>> = new EventEmitter<
    Action<T>
  >();
  @Output() menyAction: EventEmitter<Action<T>> = new EventEmitter<Action<T>>();
  @Output() childMenyAction: EventEmitter<Action<any>> = new EventEmitter<
    Action<any>
  >();
  @Output() rowEnter: EventEmitter<Action<T>> = new EventEmitter<Action<T>>();
  @Output() iconClicked: EventEmitter<Action<T>> = new EventEmitter<Action<T>>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChildren(ExpandedTableRowDirective)
  children: QueryList<ExpandedTableRowDirective>;

  tableDataSource = new MatTableDataSource<FlatRow<T>>();
  columnProperties: string[];
  columnHeaders: string[];
  columns: Column[];
  dataSource: Table<T>;
  clickedRow: FlatRow<T>;

  @Input() set data(value: Table<T>) {
    if (value) {
      this.dataSource = value;
      this.columnProperties = value.columns.map((c) => c.property);
      this.columnHeaders = value.columns.map((c) => c.header);
      this.tableDataSource.data = value.rows.map((r) => this.flatten(r));
      setTimeout(() => (this.tableDataSource.paginator = this.paginator));
      this.tableDataSource.sort = this.sort;
    }
  }

  flatten = (t: Row<T>): FlatRow<T> => ({
    ...t.row,
    highligt: t.highligt,
    inlineEdit: t.inlineEdit,
    detailTable: t.detailTable,
  });

  action(row: FlatRow<T>, action: string): void {
    this.menyAction.emit({ row, action });
  }

  rowkeyDown($event: KeyboardEvent, row: FlatRow<T>): void {
    if ($event.key === 'Enter') {
      this.rowEnter.emit({ row, action: ActionsIds.rowKeyDown });
    }
  }

  rowClick($event: MouseEvent, row: FlatRow<T>): void {
    this.clickedRow = row;
    this.rowEnter.emit({ row, action: ActionsIds.rowClick });
  }

  childAction($event: Action<any>): void {
    this.childMenyAction.emit($event);
  }

  toggle($event: MatSlideToggleChange, row: any, property: string) {
    this.collapsRow(row);
    this.inlineEditAction.emit({
      row,
      action: ActionsIds.edit,
      keyValuePairs: [{ key: property, value: $event.checked }],
      cancel: () => {
        if (row[property] !== $event.checked) {
          $event.source.toggle();
        }
      },
    });
  }

  collapsRow(row: any): void {
    this.children
      .toArray()
      .find((directive) => directive.row === row)
      ?.toggle();
  }

  iconClick($event: MouseEvent, row) {
    this.iconClicked.emit({ row, action: ActionsIds.iconClick });
    $event.stopPropagation();
  }
}
