import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  Buttons,
  ConfirmationDialogComponent,
  ConfirmationMessage,
} from '@app/components/utils/confirmation-dialog/confirmation-dialog.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {IDialogResult} from '@app/components/utils/confirmation-dialog/IDialogResult';
import {AppConfigService} from '@app/utils/services/config/app-config.service';
import {SessionKeys} from "@app/constants/session-keys";

@Component({
  selector: 'faxe-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {

  viewMode: boolean;

  constructor(
    private router: Router,
    private confirmationDialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.setVeiwMode(sessionStorage.getItem(SessionKeys.viewMode));
  }

  onAbout(): void {
    this.openConfirm()
      .afterClosed()
      .subscribe((iDialogResult) => {
        if (iDialogResult.button === Buttons.fortsatt) {
          this.router.navigate(['logout']);
        }
      });
  }

  setVeiwMode(value: string) {
    this.viewMode = value === null ? false : value === 'true';
  }

  home(): void {
    this.router.navigate(['/']);
  }

  openConfirm = (): MatDialogRef<ConfirmationDialogComponent<IDialogResult>, IDialogResult> => {
    const message: ConfirmationMessage = {} as ConfirmationMessage;
    message.title = 'Faxe v.' + AppConfigService.deploymentConfigData.deploymentDateTime;
    message.message = 'Hjälp oss göra Faxe bättre genom att skicka förbättringsförslag till ' +
      'feedback@faxeit.se. Tack för din medverkan!';
    message.primaryButton = Buttons.stang;
    return this.confirmationDialog.open(ConfirmationDialogComponent, {
      data: message,
    });
  };

}
