import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isNotEmpty} from '@app/utils/functions/common-functions';
import {ResultCode} from '@app/rest-client/faxe/models/result-code';
import {fetchAllResultCodes} from '@app/store/faxe/results-code/result-code.actions';
import {selectResultCode} from '@app/store/faxe/results-code/result-code.selectos';

@Injectable({
  providedIn: 'root'
})
export class ResultCodeFacadeService {

  constructor(private store: Store<RootState>) {
  }

  fetchAllResultCodes(issuer: string): Observable<ResultCode[]> {
    this.store.dispatch(
      fetchAllResultCodes(
        {issuerStack: [issuer]}));

    return this.store.pipe(
      select(selectResultCode()),
      filter((data) => isNotEmpty(data)));
  }

}
