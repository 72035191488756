import {createSelector, MemoizedSelector} from '@ngrx/store';
import {ClubLogo} from '@app/rest-client/faxe/models/club-logo';
import {getClubLogoState} from '@app/store/faxe/faxe-selectors';
import {selectAllClubLogo} from '@app/store/faxe/club-logo/club-logo.reducer';


// eslint-disable-next-line @typescript-eslint/ban-types
export const selectClubLogoAll: MemoizedSelector<object, ClubLogo[]> = createSelector(
  getClubLogoState,
  selectAllClubLogo
);

export const selectClubLogo = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, ClubLogo[]> =>
  createSelector(
    getClubLogoState,
    selectClubLogoAll,
    (state, all) => all
  );



