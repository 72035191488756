<div class="faxe-banner">
  <span class="faxe-banner__app-name" (click)="home()">
    <img class="faxe-banner__logo" src="assets/icons/faxe.svg" alt="Logo" />
    <span class="title" (click)="home()"> Faxe</span>
  </span>

  <div class="faxe-banner">
    <span class="faxe-banner__user"></span>
    <span class="faxe-banner__court"></span>
    <span *ngIf="!viewMode"
      class="faxe-banner__logout"
      (click)="onAbout()"
    >
      Om
    </span>
  </div>
</div>
