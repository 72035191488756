import { createSelector } from '@ngrx/store';
import {getClientSlicesStore} from '@app/store/client-slices/client-slices-selectors';

export const getDialogStoreState = createSelector(
  getClientSlicesStore,
  (clientStore) => clientStore.dialog
);

export const getDialogStoreStatus = createSelector(
  getDialogStoreState,
  (dialogdata) => dialogdata.status
);

export const isDialogClosed = createSelector(
  getDialogStoreState,
  (dialogdata) => !dialogdata.dialogOppnad
);

export const dialogOppen = createSelector(getDialogStoreState, (dialogData) => dialogData.dialogOppnad);
