<h3 *ngIf="heading && heading.length">{{ heading }}</h3>
<ng-container [formGroup]="formGroup">
  <mat-chip-list #chiplist
                 [multiple]="true">
    <ng-container *ngFor="let item of items">
      <mat-chip [matBadge]=item.badge [ngClass]="{
      'itemClass' : itemClassDefault,
      'big': item.fault === undefined,
      'big-nok': item?.fault,
      'big-ok': item.fault !== undefined &&!item?.fault,
      'big-highligt': item.glow
      }"
                *ngIf="!selectedItems.length || isSelected(item) || show(item)"
                [selected]="isSelected(item)"
                (click)="onClick(item)"
                (keydown)="onKeyPress($event, item)"
                color="custom"
      >
        <mat-icon
          svgIcon="done"
          *ngIf="isSelected(item)"
          class="chip-ikon"
        ></mat-icon>
        {{ item.displayname }}
      </mat-chip>

    </ng-container>
    <input [placeholder]=inputHeading *ngIf="enableInput && !this.selectedItems.length"
           type="number"
           [attr.inputmode]="inputModeType"
           autocomplete="off"
           [matChipInputFor]="chiplist"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           formControlName="newvalue"
           (focusout)="onFocusout()"
           (matChipInputTokenEnd)="add($event)"/>
  </mat-chip-list>
</ng-container>
