export enum MessageTypeEnum {
  ok = 'OK',
  error = 'ERROR',
}

export enum MessageShowInPresentationStateEnum {
  page = 'PAGE',
  dialog = 'DIALOG',
  whatEver = 'WHATEVER',
}
