import {createAction, props} from '@ngrx/store';
import {ClubLogo} from '@app/rest-client/faxe/models/club-logo';

const prefix = '[FAXE-CLUB-LOGO]';

export const fetchAllClubLogos = createAction(
  `${prefix} - fetch all`,
  props<{ issuerStack: string[] }>()
);

export const getClubLogos = createAction(
  `${prefix} - get`,
  props<{ issuerStack: string[] }>()
);

export const addManyClubLogos = createAction(
  `${prefix} - add many`,
  props<{ models: ClubLogo[]; issuerStack: string[] }>()
);
