/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Rider } from '../models/rider';
import { RiderExtendedSignalR } from '../models/rider-extended-signal-r';
import { RiderResult } from '../models/rider-result';

@Injectable({
  providedIn: 'root',
})
export class RidersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation riders
   */
  static readonly RidersPath = '/riders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riders()` instead.
   *
   * This method doesn't expect any request body.
   */
  riders$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Rider>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RidersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rider>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `riders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  riders(params?: {
  }): Observable<Array<Rider>> {

    return this.riders$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Rider>>) => r.body as Array<Rider>)
    );
  }

  /**
   * Path part for operation ridersPost
   */
  static readonly RidersPostPath = '/riders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ridersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ridersPost$Response(params: {
    body: Array<Rider>
  }): Observable<StrictHttpResponse<Array<Rider>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RidersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rider>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ridersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ridersPost(params: {
    body: Array<Rider>
  }): Observable<Array<Rider>> {

    return this.ridersPost$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Rider>>) => r.body as Array<Rider>)
    );
  }

  /**
   * Path part for operation riderExtended
   */
  static readonly RiderExtendedPath = '/riders/extended';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riderExtended()` instead.
   *
   * This method doesn't expect any request body.
   */
  riderExtended$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RiderExtendedSignalR>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RiderExtendedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RiderExtendedSignalR>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `riderExtended$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  riderExtended(params?: {
  }): Observable<Array<RiderExtendedSignalR>> {

    return this.riderExtended$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RiderExtendedSignalR>>) => r.body as Array<RiderExtendedSignalR>)
    );
  }

  /**
   * Path part for operation riderresultsGet
   */
  static readonly RiderresultsGetPath = '/riderresults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `riderresultsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  riderresultsGet$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RiderResult>>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.RiderresultsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RiderResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `riderresultsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  riderresultsGet(params?: {
  }): Observable<Array<RiderResult>> {

    return this.riderresultsGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RiderResult>>) => r.body as Array<RiderResult>)
    );
  }

  /**
   * Path part for operation setHandled
   */
  static readonly SetHandledPath = '/riders/sethandled';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setHandled()` instead.
   *
   * This method doesn't expect any request body.
   */
  setHandled$Response(params: {
    riderId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.SetHandledPath, 'post');
    if (params) {
      rb.query('riderId', params.riderId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setHandled$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setHandled(params: {
    riderId: string;
  }): Observable<void> {

    return this.setHandled$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setUnhandled
   */
  static readonly SetUnhandledPath = '/riders/setunhandled';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUnhandled()` instead.
   *
   * This method doesn't expect any request body.
   */
  setUnhandled$Response(params: {
    riderId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RidersService.SetUnhandledPath, 'post');
    if (params) {
      rb.query('riderId', params.riderId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUnhandled$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setUnhandled(params: {
    riderId: string;
  }): Observable<void> {

    return this.setUnhandled$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
