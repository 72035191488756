import { ActionReducerMap } from '@ngrx/store';
import { dialogStoreReducer } from './dialog/dialog-store-reducer';
import { DialogStoreState } from './dialog/dialog-store-state';
import {operationStoreReducer} from '@app/store/client-slices/operation/operation-store-reducer';
import {OperationStoreState} from '@app/store/client-slices/operation/operation-store-state';
import {signalRStoreReducer} from '@app/store/client-slices/signal-r/signal-r-store-reducer';
import {SignalRStoreState} from '@app/store/client-slices/signal-r/signal-r-store-state';

export interface ClientSlicesStore {
  readonly dialog: DialogStoreState;
  readonly operation: OperationStoreState;
  readonly signalR: SignalRStoreState;
}

export const clientSlicesReducers: ActionReducerMap<ClientSlicesStore> = {
  dialog: dialogStoreReducer,
  operation: operationStoreReducer,
  signalR: signalRStoreReducer
};
