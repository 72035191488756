import {Action, createReducer} from '@ngrx/store';

export const rootInitialState = {
};

const featureReducer = createReducer(
  rootInitialState,
);

export const rootReducer = (
  state: any,
  action: Action
): any => featureReducer(state, action);
