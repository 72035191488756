import {Injectable} from '@angular/core';
import {ApiConfiguration as faxeConfig} from '@app/rest-client/faxe/api-configuration';
import {AppConfigService} from '@app/utils/services/config/app-config.service';


@Injectable({
  providedIn: 'root',
})
export class ApiConfigurationsService {
  constructor(
    private faxeService: faxeConfig
  ) {
  }

  init(): void {
    console.log('Running: ' + AppConfigService.environmentConfigData.name);
    this.faxeService.rootUrl = AppConfigService.environmentConfigData.faxeServiceRootUrl;
  }
}
