
import {createReducer, on, Action} from '@ngrx/store';

import {onOperationStatus} from '@app/store/client-slices/operation/operation.actions';
import {
  initialOperationStoreState,
  OperationStoreState
} from '@app/store/client-slices/operation/operation-store-state';

const featureReducer = createReducer(
  initialOperationStoreState,
  on(onOperationStatus, (_,action) => ({
    correlationId: action.model.correlationId,
    status: action.model.status,
    operationId: action.model.operationId,
    callStack:  action.model.callStack
  }))
);

export const operationStoreReducer = (
  state: OperationStoreState,
  action: Action
): OperationStoreState => featureReducer(!state ? initialOperationStoreState : state, action);
