import {createAction, props} from '@ngrx/store';
import {OperationStatus} from '@app/store/client-slices/operation/model/operation-status';

const prefix = '[FAXE-SYS-OPERATION]';


  export const onOperationStatus = createAction(
  `${prefix} - onOperationStatus`,
  props<{ model: OperationStatus }>()
);

