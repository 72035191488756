import { MessageShowInPresentationStateEnum } from './message.enum';
import {NotificationAreaTypeEnum} from '@app/components/utils/notification-area/notification-area-type.enum';

export interface Message {
  readonly id?: number;
  readonly heading?: string;
  readonly descriptionList?: string[];
  readonly bulletList?: string[];
  readonly type?: NotificationAreaTypeEnum;
  readonly showInPresentationState?: MessageShowInPresentationStateEnum;
  readonly removeMessageFromUI?: boolean;
}

export interface MessageStoreState {
  readonly data: Message[];
}

export const initMessageStoreState = {
  data: [],
} as MessageStoreState;
