<div class="confirmation-padding">
  <div mat-dialog-title>
    {{ data.title }}
  </div>
  <div mat-dialog-content>
    <div class="message">{{ data.message }}</div>
    <ul *ngIf="data.bulletList">
      <li *ngFor="let message of data.bulletList">
        {{ message }}
      </li>
    </ul>
    <div *ngIf="data.messageLista">
      <div class="message-list" *ngFor="let message of data.messageLista">
        {{ message }}
      </div>
    </div>
    <faxe-input-form *ngIf="data.useInput"
                     formCtrlName="input"
                     inputType="text"
                     inputModeType="text"
                     required=" true"
                     [parentFormGroup]="formGroup"></faxe-input-form>
  </div>
  <div class="action-buttons">
    <div mat-dialog-actions>
      <button
        *ngIf="data.primaryButton"
        mat-button
        color="primary"
        (click)="onPrimaryButton()"
        class="button-margin buttons"
      >
        {{ data.primaryButton }}
      </button>
      <button
        *ngIf="data.secondaryButton === buttons.avbryt"
        mat-button
        color="secondary"
        (click)="onSecondaryButton()"
        class="buttons"
      >
        {{ data.secondaryButton }}
      </button>
    </div>
  </div>
</div>
