import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, mergeMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EMPTY, of,} from 'rxjs';
import {isNotEmpty, push} from '@app/utils/functions/common-functions';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {
  addManyResultCodes,
  fetchAllResultCodes,
  getResultCodes
} from '@app/store/faxe/results-code/result-code.actions';
import {selectResultCodeAll} from '@app/store/faxe/results-code/result-code.selectos';
import {onOperationStatus} from '@app/store/client-slices/operation/operation.actions';
import {ResultCodesService} from '@app/rest-client/faxe/services/result-codes.service';

@Injectable()
export class ResultCodeEffects {

  fetchResultCodeEffect = createEffect(() => this.actions$.pipe(
    ofType(fetchAllResultCodes),
    withLatestFrom(this.store.select(selectResultCodeAll)),
    exhaustMap(([action, state]) => {

      if (isNotEmpty(state)) {
        return EMPTY;
      }

      const actions = [];
      actions.push(getResultCodes({
        issuerStack: push(action.issuerStack,'fetchResultCodeEffect')
      }));
      return of(...actions);
    }),
    catchError(() => EMPTY)
    )
  );

  getResultCodeEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(getResultCodes),
      exhaustMap((action) => this.service.resultCodes()
        .pipe(mergeMap((response) => {
            const actions = [];
            actions.push(addManyResultCodes(
              {models: response, issuerStack: push(action.issuerStack,'getFenceEffect')}));
            return of(...actions);
          }),
          catchError((e) =>
            of(onOperationStatus({model: {
                status: e.status,
                operationId:'resultCodes',
                callStack: push(action.issuerStack, 'resultCodes')}}))
          )
        )),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private service: ResultCodesService,
    private actions$: Actions,
    private store: Store<FaxeStore>,
  ) {
  }
}
