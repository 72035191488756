import { ErrorMessageCodeEnum } from './error-message-code.enum';

export interface ErrorMessageData {
  code: ErrorMessageCodeEnum;
  title: string;
  text: string;
  severity: SeverityEnum;
  type: TypeEnum;
  buttons?: string[];
  detailList?: string[];
  correlationId?: string;
}

export interface ErrorMessageCode {
  code: string;
  replacements?: { [key: string]: string };
}

export enum SeverityEnum {
  error = 'F',
  warning = 'W',
  information = 'I',
}

export enum TypeEnum {
  banner = 'B',
  modalDialog = 'M',
  validation = 'V',
}
