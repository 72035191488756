import {createSelector, MemoizedSelector} from '@ngrx/store';
import {getFenceResultState} from '@app/store/faxe/faxe-selectors';
import {selectAllFenceResult} from '@app/store/faxe/fence-result/fence-result.reducer';
import {FenceResult} from '@app/rest-client/faxe/models/fence-result';



// eslint-disable-next-line @typescript-eslint/ban-types
export const selectFenceResultAll: MemoizedSelector<object, FenceResult[]> = createSelector(
  getFenceResultState,
  selectAllFenceResult
);


export const selectFenceResult = ():
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, FenceResult[]> =>
  createSelector(
    getFenceResultState,
    selectFenceResultAll,
    (state, all) => all
  );


export const selectFenceResultByRiderId = (riderId: string):
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, FenceResult> =>
  createSelector(
    getFenceResultState,
    selectFenceResultAll,
    (state, all) => all
        .find(x => x.riderId === riderId)
  );
