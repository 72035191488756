import {createAction, props} from '@ngrx/store';
import {ResultCode} from '@app/rest-client/faxe/models/result-code';

const prefix = '[FAXE-RESULT-CODE]';

export const fetchAllResultCodes = createAction(
  `${prefix} - fetch all`,
  props<{ issuerStack: string[] }>()
);

export const getResultCodes = createAction(
  `${prefix} - get`,
  props<{ issuerStack: string[] }>()
);

export const addManyResultCodes = createAction(
  `${prefix} - add many`,
  props<{ models: ResultCode[]; issuerStack: string[] }>()
);
