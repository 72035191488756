import {EnvironmentConfigData} from '@app/utils/services/config/model/environment-config-data';
import {DeploymentConfigData} from '@app/utils/services/config/model/deployment-config-data';


export class AppConfigService {

  static environmentConfigData: EnvironmentConfigData = {};
  static deploymentConfigData: DeploymentConfigData = {};


  static initEnvironmentConfigData(environmentConfig: EnvironmentConfigData): void {
    AppConfigService.environmentConfigData = {
      ...environmentConfig
    };
  }

  static initDeployment(deploymentConfigData: DeploymentConfigData): void {
    AppConfigService.deploymentConfigData = {
      ...deploymentConfigData
    };
  }
}
