export enum NotificationAreaTypeEnum {
  ok = 'ok',
  warn = 'warn',
  error = 'error',
  info = 'info',
}

export enum NotificationAreaPresentationStateEnum {
  page = 'PAGE',
  dialog = 'DIALOG',
}
