<table mat-table [dataSource]="tableDataSource" matSort aria-describedby="RaceEventDataItem">
  <ng-container *ngFor="let column of dataSource?.columns">
    <ng-container *ngIf="!column.menuItem">
      <ng-container matColumnDef={{column.property}}>
        <th scope="col" mat-header-cell [ngClass]="column.class" *matHeaderCellDef
            mat-sort-header>{{column.header}}</th>
        <td mat-cell *matCellDef="let row" ngClass="{{row['cellMetaMap']?.get(column.property)?.cellClass}}">
          <ng-container *ngIf=" (row[column.property] | typeof) === 'array'">
            <div *ngFor="let element of row[column.property]">
              <span [innerHtml]="element | highlight: row['highligt']: 'redbold'"></span>
            </div>
          </ng-container>
          <ng-container *ngIf="(row[column.property] | typeof) !== 'array'
          && (row[column.property] | typeof) !== 'boolean'">
            <button mat-icon-button *ngIf="row['cellMetaMap']?.get(column.property)?.icon"
                    matRipple
                    (click)="iconClick($event, row)">
              <mat-icon [svgIcon]="row['cellMetaMap']?.get(column.property)?.icon"></mat-icon>
            </button>
            {{row[column.property]}}
          </ng-container>
          <ng-container *ngIf="(row[column.property] | typeof) !== 'array'
          && (row[column.property] | typeof) === 'boolean'">
            <mat-slide-toggle (change)="toggle($event, row, row['inlineEdit'])"
                              *ngIf="row['inlineEdit'] === column.property"
                              [checked]=row[column.property]>
            </mat-slide-toggle>
            <mat-icon *ngIf="row['inlineEdit'] !== column.property"
                      [ngClass]="row[column.property]? 'green' : 'red'"
                      [svgIcon]="row[column.property]? 'done' : 'close'"
            ></mat-icon>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="column.menuItem">
      <ng-container matColumnDef={{column.property}}>
        <th scope="col" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="center"
            (click)="$event.stopImmediatePropagation()"
            (keydown)="$event.stopImmediatePropagation()"
        >
          <button mat-icon-button mat-button [matMenuTriggerFor]="meraMeny">
            <mat-icon svgIcon="more_horiz" class="grey"></mat-icon>
          </button>
          <mat-menu #meraMeny="matMenu" class="meny">
            <button *ngFor="let menuItem of column.menuItem" mat-menu-item (click)="action(row, menuItem.action)">
              {{menuItem.header}}
            </button>
          </mat-menu>
        </td>
      </ng-container>
    </ng-container>
  </ng-container>
  <tr
    mat-header-row
    *matHeaderRowDef="columnProperties"
    class="header-row"
    tabindex="0"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columnProperties;"
    tabindex="0"
    class="element-row keyboardFocusRow"
    [ngClass]="{'slim': dataSource?.slim}"
    (keydown)="rowkeyDown($event, row)"
    (click)="rowClick($event, row)"
    faxeExpandedTableRow
    [detailRow]="row"
    [templateRef]="tpl"
  ></tr>
</table>

<mat-paginator #paginator *ngIf="dataSource?.showPaginator"
               class="paginator"
               [pageSizeOptions]="[200, 400, 800]"
></mat-paginator>

<ng-template #tpl let-row="$implicit">
  <tr *ngIf="row.detailTable" class="mat-row">
    <td colspan="4">
      <faxe-table [data]="row.detailTable"
                  (menyAction)="childAction($event)"></faxe-table>
    </td>
  </tr>
</ng-template>

