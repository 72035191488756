import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ReloadRouteUtilityService} from '@app/utils/services/reload-route/reload-route-utility.service';
import {
  ConfirmationDialogComponent,
} from '@app/components/utils/confirmation-dialog/confirmation-dialog.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {IDialogResult} from '@app/components/utils/confirmation-dialog/IDialogResult';
import {map} from 'rxjs/operators';
import {PasswordService} from '@app/utils/services/password/password.service';
import {PageId} from '@app/constants/page-id';

@Injectable({
  providedIn: 'root',
})
export class AppRouteGuard implements CanActivate, CanActivateChild {

  private pathMatrix = new Map<any, string[]>();

  constructor(
    private router: Router,
    private reloadRouteUtilityService: ReloadRouteUtilityService,
    private confirmationDialog: MatDialog,
    private passwordService: PasswordService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    this.reloadRouteUtilityService.storeCurrentUrlInSessionStorage(state.url);
    return this.guard(next);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    this.reloadRouteUtilityService.storeCurrentUrlInSessionStorage(state.url);
    return this.guard(next);
  }

  guard(next?: ActivatedRouteSnapshot): Observable<boolean> {

    if(this.getResolvedUrl(next).includes(PageId.fenceResultAudience)){
      return of(true);
    }

    if (this.passwordService.hasValidPassword()) {
      return of(true);
    }

    return this.openLogin()
      .afterClosed()
      .pipe(map(result => {
        this.passwordService.setPassword(result.input);
        return this.passwordService.hasValidPassword();
      }));
  }



  openLogin = (): MatDialogRef<ConfirmationDialogComponent<IDialogResult>, IDialogResult> =>
    this.confirmationDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Ange tävlingslösenord',
        message: undefined,
        bulletList: undefined,
        primaryButton: 'OK',
        secondaryButton: 'Avbryt',
        correlationId: 'login',
        useInput: true
      },
    });

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

}
