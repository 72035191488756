import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isNotEmpty} from '@app/utils/functions/common-functions';
import {RaceClass} from '@app/rest-client/faxe/models/race-class';
import {fetchAllRaceClasses} from '@app/store/faxe/race-class/race-class.actions';
import {selectRaceClass} from '@app/store/faxe/race-class/race-class.selectos';

@Injectable({
  providedIn: 'root'
})
export class RaceClassFacadeService {

  constructor(private store: Store<RootState>) {
  }

  fetchRaceClass(issuer: string): Observable<RaceClass[]> {
    this.store.dispatch(
      fetchAllRaceClasses(
        {issuerStack: [issuer]}));

    return this.store.pipe(
      select(selectRaceClass()),
      filter((data) => isNotEmpty(data)));
  }

}
