export enum KombinationstypEnum {
  can = '*',
  must1of1 = '1',
  must1of2 = '2',
  must1of3 = '3',
  can1of1 = '?',
  mustAtLest1 = '+',
  can1AndExcludeOthers = '#',
  canNExcludeOthers= '!',
}
