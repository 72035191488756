import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';

@Injectable({
  providedIn: 'root',
})
export class LandingFacadeService {

  constructor(private store: Store<RootState>) {
  }

}
