import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, mergeMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EMPTY, of,} from 'rxjs';
import {isNotEmpty, push} from '@app/utils/functions/common-functions';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {
  addManyFenceResult,
  fetchAllFenceResult,
  getFenceResult, postFenceResult
} from '@app/store/faxe/fence-result/fence-result.actions';
import {selectFenceResultAll} from '@app/store/faxe/fence-result/fence-result.selectos';
import {FenceResultsService} from '@app/rest-client/faxe/services/fence-results.service';
import {onOperationStatus} from '@app/store/client-slices/operation/operation.actions';
import {OperationStatusEnum} from '@app/store/client-slices/operation/model/operationstatusenum';

@Injectable()
export class FenceResultEffects {

  fetchFenceResultEffect = createEffect(() => this.actions$.pipe(
      ofType(fetchAllFenceResult),
      withLatestFrom(this.store.select(selectFenceResultAll)),
      exhaustMap(([action, state]) => {

        if (isNotEmpty(state)) {
          return EMPTY;
        }

        const actions = [];
        actions.push(getFenceResult({
          issuerStack: push(action.issuerStack, 'fetchFenceResultEffect')
        }));
        return of(...actions);
      }),
      catchError(() => EMPTY)
    )
  );

  getFenceResultEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(getFenceResult),
      mergeMap((action) => this.service
        .fenceresults()
        .pipe(mergeMap((response) => {
            const actions = [];
            actions.push(addManyFenceResult(
              {models: response, issuerStack: push(action.issuerStack, 'getFenceResultEffect')}));
            return of(...actions);
          }),
          catchError((e) =>
            of(onOperationStatus({model: {
              status: e.status,
              operationId:'getFenceResultEffect',
              callStack: push(action.issuerStack, 'getFenceResultEffect')}})
          ))
        )),
      catchError(() => EMPTY)
    )
  );

  postFenceResultEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(postFenceResult),
      mergeMap((action) => this.service
        .fenceResultPost({body: action.fenceResult})
        .pipe(mergeMap(() => {
            const actions = [];
            actions.push(onOperationStatus({model: {
                status: OperationStatusEnum.ok,
                operationId:'postFenceResult',
                correlationId: action.correlationId,
                callStack: push(action.issuerStack, 'postFenceResult')}}));
            return of(...actions);
          }),
          catchError((e) =>
            of(onOperationStatus({model: {
                status: e.status,
                operationId:'postFenceResult',
                callStack: push(action.issuerStack, 'postFenceResult')}})
            )
          )
        )),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private service: FenceResultsService,
    private actions$: Actions,
    private store: Store<FaxeStore>,
  ) {
  }
}
