import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import {EnvironmentConfigData} from '@app/utils/services/config/model/environment-config-data';
import {AppConfigService} from '@app/utils/services/config/app-config.service';
import {LocalUrls} from '@assets/contract-faxe/local-urls';

const loadApp = (environmentConfigData: EnvironmentConfigData) => {

  AppConfigService.initEnvironmentConfigData(environmentConfigData);

  if (environmentConfigData.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
};

fetch(LocalUrls.environment)
  .then(response => response.json())
  .then(json =>loadApp(json))
  .catch(() => {
  });
