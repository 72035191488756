import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, Observable, throwError} from 'rxjs';
import {
  ErrorMessageCode,
  ErrorMessageData,
  SeverityEnum,
  TypeEnum,
} from './error-message-data';
import {ReloadRouteUtilityService} from '../reload-route/reload-route-utility.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ErrorMessageCodeEnum} from './error-message-code.enum';
import {HttpErrorResponse} from '@angular/common/http';
import {RootState} from '@app/store/root-state';
import {Store} from '@ngrx/store';
import {stangDialog} from '@app/store/client-slices/dialog/dialog-store-actions';
import errorJson from '@assets/error-message/error-messages.json';
import detailJson from '@assets/error-message/detail-messages.json';
import {ConfirmationDialogComponent} from '@app/components/utils/confirmation-dialog/confirmation-dialog.component';
import {MessageShowInPresentationStateEnum} from '@app/store/messages/message.enum';
import {Message} from '@app/store/messages/message-state';
import {NotificationAreaTypeEnum} from '@app/components/utils/notification-area/notification-area-type.enum';
import {typeOf} from '@app/components/utils/functions/type-of';
import {message} from '@app/store/messages/message-actions';

enum HttpStatus {
  noStatus = 0,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  timeOut = 504,
}

type HttpErrorCode = {
  errorCode?: ErrorMessageCode;
  detailErrorCodeList?: ErrorMessageCode[];
  errorId: string;
};

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageUtilService {
  constructor(
    private store: Store<RootState>,
    private router: Router,
    private reloadRouteUtilityService: ReloadRouteUtilityService,
    private confirmationDialog: MatDialog,
  ) {
  }

  public static getErrorMessage(
    errorCode: ErrorMessageCode,
    errorDetailCodes?: ErrorMessageCode[],
    errorId?: string,
    correlationId?: string
  ): ErrorMessageData {
    errorCode = ErrorMessageUtilService.translateCode(errorCode);
    ErrorMessageUtilService.setDefaultCode(errorCode);
    //Hämta meddelande från jsonfilen
    const errorMessageObject = {
      ...errorJson.errorCodes.find(
        (errorMessage) => errorMessage.code === errorCode.code
      ),
    };

    // om koden inte hittades meddela detta
    if (errorMessageObject.code === undefined) {
      errorMessageObject.title =
        'Meddelandekod: ' + errorCode.code + ' hittades inte!';
      errorMessageObject.severity = 'F';
      errorMessageObject.type = 'B';
    }

    if (errorMessageObject.code === ErrorMessageCodeEnum.i000) {
      errorMessageObject.text = [errorMessageObject.text, errorId].join('');
    }

    const errorMessageDetailsObject = ErrorMessageUtilService.getErrorMessageDetails(
      errorDetailCodes
    );

    if (errorCode?.replacements) {
      errorMessageObject.text = ErrorMessageUtilService.replaceDynamicData(
        errorMessageObject.text,
        errorCode
      );
    }
    return {
      code: errorMessageObject.code as ErrorMessageCodeEnum,
      title: errorMessageObject.title,
      text: errorMessageObject.text,
      severity: errorMessageObject.severity as SeverityEnum,
      type: errorMessageObject.type as TypeEnum,
      buttons: errorMessageObject.buttons,
      detailList: errorMessageDetailsObject,
      correlationId,
    };
  }

  private static getHttpErrorCode(
    httpErrorResponse: HttpErrorResponse
  ): HttpErrorCode {
    try {
      const httpErrorCode: HttpErrorCode = ErrorMessageUtilService.parseHttpErrorCode(
        httpErrorResponse
      );

      return typeOf(httpErrorCode, 'errorId')
        ? httpErrorCode
        : ErrorMessageUtilService.getDefaultError(httpErrorResponse);
    } catch (e) {
      return ErrorMessageUtilService.getDefaultError(httpErrorResponse);
    }
  }

  private static parseHttpErrorCode = (
    httpErrorResponse: HttpErrorResponse
  ): HttpErrorCode => {
    if (httpErrorResponse.error instanceof Array) {
      return httpErrorResponse.error[0];
    } else if (typeof httpErrorResponse?.error === 'string') {
      return JSON.parse(httpErrorResponse.error)[0];
    }
    return undefined;
  };

  private static getDefaultError = (
    httpErrorResponse: HttpErrorResponse
  ): HttpErrorCode => ({
    errorCode: {code: ErrorMessageCodeEnum.i000},
    errorId: ErrorMessageUtilService.errorId(httpErrorResponse),
  });

  private static errorId = (httpErrorResponse: HttpErrorResponse): string => {
    if (httpErrorResponse) {
      if (httpErrorResponse.error) {
        return httpErrorResponse.error[0]?.errorId;
      } else {
        return String(httpErrorResponse.status);
      }
    }
    return undefined;
  };

  private static translateCode(
    errorMessageCode: ErrorMessageCode
  ): ErrorMessageCode {
    return errorMessageCode;
  }

  private static replaceDynamicData(
    errorText: string,
    errorCode: ErrorMessageCode
  ): string {
    let replacedErrorText: string;
    for (const placeholder of Object.keys(errorCode?.replacements)) {
      replacedErrorText = errorText.replace(
        placeholder,
        errorCode?.replacements[placeholder]
      );
    }
    return replacedErrorText;
  }

  private static getErrorMessageDetails(
    errorDetailCodes: ErrorMessageCode[]
  ): string[] {
    if (!errorDetailCodes) {
      return [];
    }

    const detialsText = [];
    errorDetailCodes.forEach((detailMessageCode) => {
      const errorMessageObject = {
        ...detailJson.errorDetailCodes.find(
          (errorDetailMessage) =>
            errorDetailMessage.code === detailMessageCode.code
        ),
      };

      //Om detaljkoden inte hittades meddela detta!
      if (errorMessageObject.code === undefined) {
        detialsText.push(
          'Detaljkoden: ' + detailMessageCode.code + ' hittades inte!'
        );
      } else {
        if (detailMessageCode?.replacements) {
          errorMessageObject.text = ErrorMessageUtilService.replaceDynamicData(
            errorMessageObject.text,
            detailMessageCode
          );
        }
        detialsText.push(errorMessageObject.text);
      }
    });
    return detialsText;
  }

  private static setDefaultCode(errorCode: ErrorMessageCode) {
    if (
      !errorCode.code.startsWith('I')
    ) {
      errorCode.code = ErrorMessageCodeEnum.i000;
    }
  }

  private static hasErrorAttached(httpErrorResponse: HttpErrorResponse) {
    return (
      httpErrorResponse &&
      httpErrorResponse.error &&
      httpErrorResponse.error.length > 0
    );
  }

  public getAction(httpErrorResponse: HttpErrorResponse): Observable<any> {
    if (ErrorMessageUtilService.hasErrorAttached(httpErrorResponse)) {
      const httpErrorCode: HttpErrorCode = ErrorMessageUtilService.getHttpErrorCode(
        httpErrorResponse
      );
      return this.showMessage(
        httpErrorCode?.errorCode,
        httpErrorCode?.detailErrorCodeList,
        httpErrorCode?.errorId
      );
    }

    return this.handleByStatus(httpErrorResponse);
  }

  public showMessage(
    errorCode: ErrorMessageCode,
    detailCodes?: ErrorMessageCode[],
    errorId?: string,
    correlationId?: string
  ): Observable<any> {
    const errorMessageObject = ErrorMessageUtilService.getErrorMessage(
      !errorCode ? {code: ErrorMessageCodeEnum.i001} : errorCode,
      detailCodes,
      errorId,
      correlationId
    );
    if (errorMessageObject.type === TypeEnum.modalDialog) {
      return this.showModalDialog(errorMessageObject);
    } else if (errorMessageObject.type === TypeEnum.banner) {
      this.showBanner(errorMessageObject);
    }
    return EMPTY;
  }

  private handleByStatus(httpErrorResponse: HttpErrorResponse) {
    switch (httpErrorResponse.status) {
      case HttpStatus.noStatus:
        break;
      case HttpStatus.badRequest:
        const httpError: HttpErrorCode = ErrorMessageUtilService.getHttpErrorCode(
          httpErrorResponse
        );
        return this.showMessage(
          httpError[0].errorCode,
          httpError[0].detailErrorList,
          httpError[0].errorId
        );

      case HttpStatus.unauthorized:
        this.reloadRouteUtilityService.resetCurrentUrlInSessionStorage();
        this.store.dispatch(stangDialog({payload: {navigate: false}}));
        this.router.navigate(['login']);
        return EMPTY;

      case HttpStatus.forbidden:
        return this.showMessage({
          code: ErrorMessageCodeEnum.i000,
        });
      default:
    }
    throwError(() => new Error('Failed to handle error.'));
  }

  private showBanner(errorMessageObject: ErrorMessageData) {
    let severity = NotificationAreaTypeEnum.error;
    if (errorMessageObject.severity === SeverityEnum.information) {
      severity = NotificationAreaTypeEnum.info;
    } else if (errorMessageObject.severity === SeverityEnum.warning) {
      severity = NotificationAreaTypeEnum.warn;
    }

    const payload: Message = {
      id: 0,
      heading: errorMessageObject.title,
      descriptionList: errorMessageObject.text
        ? [errorMessageObject.text]
        : undefined,
      bulletList: errorMessageObject.detailList,
      type: severity,
      showInPresentationState: MessageShowInPresentationStateEnum.whatEver,
    };
    this.store.dispatch(message({payload}));
  }

  private showModalDialog(
    errorMessageObject: ErrorMessageData
  ): Observable<any> {
    const primaryButton =
      errorMessageObject.buttons && errorMessageObject.buttons.length > 0
        ? errorMessageObject.buttons[0]
        : undefined;
    const secondaryButton =
      errorMessageObject.buttons && errorMessageObject.buttons.length > 1
        ? errorMessageObject.buttons[1]
        : undefined;

    return this.confirmationDialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: errorMessageObject.title,
          message: errorMessageObject.text,
          bulletList: errorMessageObject.detailList,
          primaryButton,
          secondaryButton,
          correlationId: errorMessageObject.correlationId,
        },
      })
      .afterClosed();
  }
}
