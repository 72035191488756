import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewChild,
} from '@angular/core';
import {NotificationAreaPresentationStateEnum} from './notification-area-type.enum';
import {Message} from '@app/store/messages/message-state';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {NotificationAreaFacadeService} from './notification-area-facade.service';
import {MessageShowInPresentationStateEnum} from '@app/store/messages/message.enum';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'faxe-notification-area',
  templateUrl: './notification-area.component.html',
})
export class NotificationAreaComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', {static: true}) wrapperRef: ElementRef;
  @Input() presentationState = NotificationAreaPresentationStateEnum.page.toString();
  @Output() renderHeight: EventEmitter<number> = new EventEmitter<number>();
  message$: Observable<Message>;
  messageToShow$: Observable<Message>;
  messageShouldBeShownInDialog = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private facade: NotificationAreaFacadeService) {
  }

  ngOnInit(): void {
    if (
      this.presentationState ===
      NotificationAreaPresentationStateEnum.page.toString()
    ) {
      this.message$ = this.facade.getPageMessages();
    } else {
      this.message$ = this.facade.getDialogMessages();
    }

    combineLatest([this.message$, this.facade.isDialogOpen$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([message, isDialogOpen]) => {
        this.messageShouldBeShownInDialog = isDialogOpen;
        if(this.shouldShowMessgeCloneAndConsumeOriginal(isDialogOpen,message)){
          this.showMessgeCloneAndConsumeOriginal(message);
        }
      });
  }

  shouldShowMessgeCloneAndConsumeOriginal = (isDialogOpen: boolean, message: Message): boolean =>
  (!isDialogOpen && this.isPresentationPage() && this.isShowIn(message, MessageShowInPresentationStateEnum.page))
    || (!this.isPresentationPage() && this.isShowIn(message, MessageShowInPresentationStateEnum.dialog));

  isPresentationPage = (): boolean =>
    this.presentationState === NotificationAreaPresentationStateEnum.page;

  isShowIn = (message: Message, showIn: MessageShowInPresentationStateEnum): boolean =>
    message != null &&
    (message.showInPresentationState === MessageShowInPresentationStateEnum.whatEver ||
      message.showInPresentationState ===  showIn);

  onRemoveMessage(): void {
    this.messageToShow$ = of(null);
  }

  onRenderedHeight(renderedHeight: number): void {
    this.renderHeight.emit(this.messageShouldBeShownInDialog ? 0 : renderedHeight);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private showMessgeCloneAndConsumeOriginal(original: Message): void {
    if (original.removeMessageFromUI) {
      this.messageToShow$ = of(null);
    } else {
      this.messageToShow$ = of({...original});
    }
    this.facade.hanteraKonsumerad(original.id);
  }

}
