import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducers} from '@app/store/root-state';
import {RootEffects} from '@app/store/root-effects';
import {ApiConfigurationsService} from '@app/store/api-configurations.service';
import {ClientSlicesStoreModule} from '@app/store/client-slices/client-slices-store.module';
import {MessageStoreModule} from '@app/store/messages/message-module';
import {FaxeStoreModule} from '@app/store/faxe/faxe-store-module';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (apiConf: ApiConfigurationsService) => () => apiConf.init(),
      deps: [ApiConfigurationsService],
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ClientSlicesStoreModule,
    MessageStoreModule,
    FaxeStoreModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([RootEffects]),
  ],
})
export class RootStoreModule {}
