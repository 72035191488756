

export interface SignalRStoreState {
  readonly status?: string;
  readonly connectionId?: string;
  readonly action?: string;
  readonly errorName?: string;
  readonly errorMessage?: string;
  readonly errorStack?: string;
}

export const initialSignalRStoreState = {
  status: 'init',
  connectionId: '-',
  action: 'init'
} as SignalRStoreState;
