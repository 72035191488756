import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string, clazz?: string): string {

    if (search && typeof search === 'string') {
      search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
    }
    //const regex = new RegExp(search, 'ig');

    search.split('%')
      .forEach(
        highlight => text =  text.replace(highlight, (match) => `<span class="${clazz? clazz : 'bold'}">${match}</span>`)
      );
    return text;



  }
}
