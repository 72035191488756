
import {createReducer, on, Action} from '@ngrx/store';

import {onSignalRStatus} from '@app/store/client-slices/signal-r/signal-r.actions';
import {initialSignalRStoreState, SignalRStoreState} from '@app/store/client-slices/signal-r/signal-r-store-state';

const featureReducer = createReducer(
  initialSignalRStoreState,
  on(onSignalRStatus, (_,action) => ({
    status: action.model.status,
    connectionId: action.model.connectionId,
    action: action.model.action,
    errorName: action.model.errorName,
    errorMessage: action.model.errorMessage,
    errorStack: action.model.errorStack,
  }))
);

export const signalRStoreReducer = (
  state: SignalRStoreState,
  action: Action
): SignalRStoreState => featureReducer(!state ? initialSignalRStoreState : state, action);
