import {SessionKeys} from '@app/constants/session-keys';

export class SessionService{

  public static get(key: string, consume?: boolean): string {
    const item = window.sessionStorage.getItem(key);
    if (item && consume) {
      window.sessionStorage.removeItem(key);
    }
    return item;
  }

  public static set(key: string, value: string): void {
    window.sessionStorage.setItem(key, value);
  }

  public static remove(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  public static clear(): void {
    window.sessionStorage.clear();
  }

  public static hasKey(key: string): boolean {
    return !!window.sessionStorage.getItem(key);
  }
}
