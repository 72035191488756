import {OperationStatus} from '@app/store/client-slices/operation/model/operation-status';
import {OperationStatusEnum} from '@app/store/client-slices/operation/model/operationstatusenum';


export interface OperationStoreState {
  readonly status?: OperationStatusEnum;
  readonly operationId?: string;
  readonly correlationId?: string;
  readonly callStack?: string[];
}

export const initialOperationStoreState = {
  status: OperationStatusEnum.ok,
  operationId: 'init',
  correlationId: 'init',
  callStack: []
} as OperationStoreState;
