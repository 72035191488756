import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {ClubLogo} from '@app/rest-client/faxe/models/club-logo';
import {addManyClubLogos} from '@app/store/faxe/club-logo/club-logo.actions';

export const identifier = (a: ClubLogo): number => a.logoId;

export const adapter: EntityAdapter<ClubLogo> = createEntityAdapter<ClubLogo>({
  selectId: identifier,
});

export type ClubLogoEntityState = EntityState<ClubLogo>;

export const initialState: ClubLogoEntityState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(addManyClubLogos, (state, {models}) => adapter.addMany(models, state)),
);

export const clubLogoReducer: (state: (ClubLogoEntityState | undefined), action: Action) =>
  EntityState<ClubLogo> = (state: ClubLogoEntityState | undefined, action: Action) =>
  reducer(state, action);

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllClubLogo = selectAll;


