import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Interceptor som lägger på X-Request-Timeout för vissa anrop (t ex skick som tar längre tid än 30s).
 */
@Injectable()
export class AppRequestTimeoutInterceptor implements HttpInterceptor {
  private static readonly requestTimeoutHeader = 'X-Request-Timeout';
  private static readonly requestTimeoutSkickaMs = '210000';
  private static readonly skickaSubPath = '/api/leverans/skicka/';

  private static addRequestTimeoutIfApplicable(
    request: HttpRequest<any>
  ): HttpRequest<any> {
    if (
      request.method === 'POST' &&
      request.url.indexOf(AppRequestTimeoutInterceptor.skickaSubPath) !== -1
    ) {
      // För skicka metoden lägger vi på <requestTimeoutSkickaMs>
      return AppRequestTimeoutInterceptor.addTimeoutHeader(
        request,
        AppRequestTimeoutInterceptor.requestTimeoutSkickaMs
      );
    }
    return request;
  }

  private static addTimeoutHeader(
    request: HttpRequest<any>,
    time: string
  ): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(
        AppRequestTimeoutInterceptor.requestTimeoutHeader,
        time
      ),
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(
      AppRequestTimeoutInterceptor.addRequestTimeoutIfApplicable(request)
    );
  }
}
