import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EMPTY, of,} from 'rxjs';
import {isNotEmpty, push} from '@app/utils/functions/common-functions';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {onOperationStatus} from '@app/store/client-slices/operation/operation.actions';
import {addManyClubLogos, fetchAllClubLogos, getClubLogos} from '@app/store/faxe/club-logo/club-logo.actions';
import {selectClubLogoAll} from '@app/store/faxe/club-logo/club-logo.selectos';
import {ClubLogoService} from '@app/rest-client/faxe/services/club-logo.service';

@Injectable()
export class ClubLogoEffects {

  fetchClubLogoEffect = createEffect(() => this.actions$.pipe(
    ofType(fetchAllClubLogos),
    withLatestFrom(this.store.select(selectClubLogoAll)),
    mergeMap(([action, state]) => {

      if (isNotEmpty(state)) {
        return EMPTY;
      }

      const actions = [];
      actions.push(getClubLogos({
        issuerStack: push(action.issuerStack,'ClubLogoEffects')
      }));
      return of(...actions);
    }),
    catchError(() => EMPTY)
    )
  );

  getClubLogoEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(getClubLogos),
      mergeMap((action) => this.service.getClublogotypesList()
        .pipe(mergeMap((response) => {
            const actions = [];
            actions.push(addManyClubLogos(
              {models: response, issuerStack: push(action.issuerStack,'getClubLogoEffect')}));
            return of(...actions);
          }),
          catchError((e) =>
            of(onOperationStatus({model: {
                status: e.status,
                operationId:'getClublogotypesList',
                callStack: push(action.issuerStack, 'getClublogotypesList')}}))
          )
        )),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private service: ClubLogoService,
    private actions$: Actions,
    private store: Store<FaxeStore>,
  ) {
  }
}
