import {ActionReducerMap} from '@ngrx/store';
import {rootReducer} from './root-reducer';


export interface RootState {
  readonly application: any;
}

export const reducers: ActionReducerMap<RootState> = {
  application: rootReducer,
};
