import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import {PageWrapperModule} from '@app/components/utils/page-wrapper/page-wrapper.module';
import {ToolbarModule} from '@app/components/toolbar/toolbar.module';
import {MatIconModule} from '@angular/material/icon';
import {ButtonMenueModule} from '@app/components/shared/button-menue/button-menue.module';
import {MatListModule} from "@angular/material/list";
import {FenceResultAudienceModule} from "@app/components/fence-result-audience/fence-result-audience.module";


@NgModule({
  declarations: [LandingComponent],
  exports: [LandingComponent],
    imports: [
        CommonModule,
        PageWrapperModule,
        ToolbarModule,
        MatIconModule,
        ButtonMenueModule,
        MatListModule,
        FenceResultAudienceModule,
    ]
})
export class LandingModule { }
