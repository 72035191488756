<div [class]="cssClasses">
  <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
  <div class="notification-area_message">
    <div class="notification-area_heading">{{ message.heading }}</div>
    <ng-container *ngIf="message.descriptionList?.length > 0">
      <div
        *ngFor="let descriptionRow of message.descriptionList"
        class="notification-area_description"
      >
        <faxe-html-display
          [htmlString]="descriptionRow">
        </faxe-html-display>
      </div>
    </ng-container>
    <ul *ngFor="let bullet of message.bulletList">
      <li>{{ bullet }}</li>
    </ul>
  </div>
  <button mat-icon-button (click)="removeNotification()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
