import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  invokeValue,
  ValidatorValueFn
} from '@app/components/shared/checkbox-group/validator';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'faxe-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
})
export class InputFormComponent implements OnInit, OnDestroy {
  @Input() valueValidator: ValidatorValueFn<string>[];
  @Input() requiredInput = false;
  @Input() label: string;
  @Input() hint: string;
  @Input() high: boolean;
  @Input() placeholder = '';
  @Input() inputType = 'number';
  @Input() inputModeType = 'numeric';
  @Input() inputAutoFocus = true;
  @Input() formCtrlName: string;
  @Input() maxInputlength = 500;
  @Input() parentFormGroup: UntypedFormGroup;
  @ViewChild('inputElement') inputElement: ElementRef;

  formGroup: UntypedFormGroup = this.fb.group({
    value: new UntypedFormControl(),
    valid: new UntypedFormControl(null),
  });
  private unsubscribe$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {
  }

  clear() {
    this.formGroup.controls.value.patchValue('', {emitEvent: false});
  }

  setFocus() {
    this.inputElement.nativeElement.focus();
  }

  ngOnInit(): void {
    if (this.requiredInput) {
      this.formGroup.controls.value.setValidators(Validators.required);
    }
    if (this.valueValidator) {
      this.formGroup.controls.value.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.formGroup.controls.valid.updateValueAndValidity();
        });

      this.formGroup.controls.valid.setValidators(
        this.valueValidator.map((v) =>
          invokeValue(v, this.formGroup, (f) => f.controls.value.value)
        )
      );
    }

    this.parentFormGroup?.addControl(this.formCtrlName, this.formGroup);

  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
