import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageWrapperComponent } from './page-wrapper.component';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {RouterModule} from '@angular/router';
import {NotificationAreaModule} from '@app/components/utils/notification-area/notification-area.module';
import {SidebarModule} from 'primeng/sidebar';
import {ButtonModule} from 'primeng/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {ToolbarModule} from '@app/components/toolbar/toolbar.module';



@NgModule({
  declarations: [PageWrapperComponent],
  exports: [
    PageWrapperComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    RouterModule,
    NotificationAreaModule,
    SidebarModule,
    ButtonModule,
    MatMenuModule,
    MatIconModule,
    ToolbarModule
  ]
})
export class PageWrapperModule { }
