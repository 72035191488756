import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FenceFacadeService} from '@app/services/fence/fence-facade.service';
import {FenceResultFacadeService} from '@app/services/fence-result/fence-result-facade.service';
import {ResultCodeFacadeService} from '@app/services/result-code/result-code-facade.service';
import {RiderFacadeService} from '@app/services/rider/riders-facade.service';
import {Subject} from 'rxjs';
import {DataItem} from '@app/components/shared/model/data-item';
import {Fence} from '@app/rest-client/faxe/models/fence';
import {RiderExtendedSignalR} from '@app/rest-client/faxe/models/rider-extended-signal-r';
import {KombinationstypEnum} from '@app/constants/kombinationstyp';
import {RiderClassFence} from '@app/components/fence-result-audience/model/RiderClassFence';
import {ResultCode} from '@app/rest-client/faxe/models/result-code';
import {mapRiderClassFence, mapTable} from '@app/components/fence-result-audience/mapper/mapper-service';
import {Table} from '@app/components/shared/table/model/table';
import {RiderDataItem} from '@app/components/fence-result-audience/model/rider-data-item';
import {SessionKeys} from '@app/constants/session-keys';
import {ClubLogoFacadeService} from '@app/services/club-logo/club-logo-facade.service';
import {distict, isEmpty, isNotEmpty} from '@app/utils/functions/common-functions';
import {RaceClass} from '@app/rest-client/faxe/models/race-class';
import {RaceClassFacadeService} from '@app/services/race-class/race-class-facade.service';
import {RiderEventType} from '@app/constants/rider-event-type';
import {raceDuration, riderEventTimestamp} from '@app/components/utils/functions/domain-functions';

@Component({
  selector: 'faxe-fence-result-audience',
  templateUrl: './fence-result-audience.component.html',
  styleUrls: ['./fence-result-audience.component.scss']
})
export class FenceResultAudienceComponent implements OnInit, OnDestroy {
  riderDataItems: DataItem<RiderClassFence>[];
  fetchedRiders: RiderExtendedSignalR[];
  ridersFilteredToShow: RiderExtendedSignalR[];
  fenceDataItem: DataItem<Fence>[];
  fenceClassMap: Map<number, Fence[]>;
  riderTimeMap: Map<string, string>= new Map<string, string>();
  resultCodes: ResultCode[];
  tableDataMap: Map<string, Table<RiderDataItem>> = new Map<string, Table<RiderDataItem>>();
  riderColapsMap: Map<string, boolean> = new Map<string, boolean>();
  viewMode: boolean;
  raceClassDataItem: DataItem<RaceClass>[];
  raceClasses: RaceClass[];
  selectedClasses: number[] = [];
  clubLogosMap: Map<string, string> = new Map<string, string>();
  formGroup: UntypedFormGroup = this.fb.group({
    value: new UntypedFormControl(null),
  });
  allClassId = -1;
  allClassName = 'Aktiva klasser';
  defaultLoggoId = '39';
  selectedClassNameList: string;
  intervalId;
  private componentId: 'FenceResultAudienceComponent';
  private unsubscribe$ = new Subject<void>();

  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly fenceFacadeService: FenceFacadeService,
              private readonly fenceResultFacadeService: FenceResultFacadeService,
              private readonly resultCodeFacadeService: ResultCodeFacadeService,
              private readonly riderFacadeService: RiderFacadeService,
              private readonly clubLogofacadeServcie: ClubLogoFacadeService,
              private fb: UntypedFormBuilder,
              private readonly raceClassFacadeService: RaceClassFacadeService,
  ) {
  }

  ngOnInit(): void {
    this.setVeiwMode(sessionStorage.getItem(SessionKeys.viewMode));
    this.selectAllResultCodes();
    this.selectAllRaceClasses();
    this.timer();
  }

  selectAllRaceClasses(): void {
    this.raceClassFacadeService
      .fetchRaceClass(this.componentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(raceClasses => {
          this.raceClasses = raceClasses;
          this.raceClassDataItem = this.map(this.raceClasses);
        }
      );
  }

  map(raceClasses: RaceClass[]): DataItem<RaceClass>[] {
    const mapped: DataItem<RaceClass>[] = raceClasses
      ?.map(rc => ({
        displayname: `${rc.raceClassNumber}  (${rc.raceClassName})`,
        key: rc.raceClassId,
        value: rc,
        sortOrder: rc.raceClassNumber,
        kombinationstypEnum: KombinationstypEnum.canNExcludeOthers,
      }));

    mapped.push(({
      displayname: this.allClassName,
      key: this.allClassId,
      value: {raceClassNumber: this.allClassId, raceClassId: this.allClassId, raceId: undefined},
      sortOrder: 0,
      kombinationstypEnum: KombinationstypEnum.can1AndExcludeOthers,
      default: this.allClassId
    }));

    return mapped
      ?.sort((a, b) => a.value.raceClassNumber - b.value.raceClassNumber);
  }

  panelOpenState(value: boolean) {
    return !value;
  }

  setVeiwMode(value: string) {
    this.viewMode = value === null ? false : value === 'true';
    if (this.viewMode) {
      this.selectedClasses.push(this.allClassId);
    }
  }

  fetchAllClubLogos(riders: RiderExtendedSignalR[]): void {
    if (isEmpty(riders)) {
      return;
    }
    this.clubLogofacadeServcie.fetchAllClubLogos(this.componentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {

        riders.forEach(rider => {
          data.find(l => l.clubName === rider.rider.clubName) ?
            this.clubLogosMap.set(
              rider.rider.riderId,
              data.find(l => l.clubName === rider.rider.clubName)?.logoId.toString()) :
            this.clubLogosMap.set(
              rider.rider.riderId,
              this.defaultLoggoId);
        });
      });
  }

  fetchRidersAll(): void {
    this.riderFacadeService.fetchRiders(this.componentId, false)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(riders => {
        riders?.forEach(r => this.riderTimeMap?.set(r.rider.riderId, raceDuration(r)));
        this.fetchedRiders = riders;
        this.fetchAllClubLogos(riders);
        this.mapRiderDetails(riders);
        this.ridersToShow();
      });
  }

  mapRiderDetails(riders: RiderExtendedSignalR[]) {
    riders.forEach(rider => {
      this.tableDataMap.set(
        rider.rider.riderId,
        mapTable(rider, this.fenceClassMap, this.resultCodes));
    });
  }

  timeStringToNumber(rider: RiderExtendedSignalR): number {

    if (isNotEmpty(rider.riderEvents)) {

      const started = rider.riderEvents.find(e => e.eventType === RiderEventType.started);
      if (started) {

        const parts = started.eventTimestamp.split('T')[1]
          .split(':');
        return +((+parts[0]).toString() + (+parts[1]).toString() + (+parts[2]).toString());
      }
    }
    return (rider.rider.horseCombinationNumber + 1000) * -1;

  }

  filterRiders(riders: RiderExtendedSignalR[]): RiderExtendedSignalR[] {
    if (isNotEmpty(riders)) {
      if (this.selectedClasses.find(rc => rc === this.allClassId)) {
        return this.filterClassOnTrack(riders);
      }
      return riders.filter(r => this.selectedClasses.find(rc => r.rider.raceClassId === rc));
    }
  }

  filterClassOnTrack(riders: RiderExtendedSignalR[]): RiderExtendedSignalR[] {
    const classesOnTrack = distict(riders
        .filter(r => r.rider.isReportable)
        .map(r => r.rider),
      'raceClassId')
      .map(r => r.raceClassId);

    return riders
      .filter(rider => classesOnTrack.find(classOnTrack => rider.rider.raceClassId === classOnTrack));
  }

  selectAllResultCodes(): void {
    this.resultCodeFacadeService.fetchAllResultCodes(this.componentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resultCodes => {
          this.resultCodes = resultCodes;
          this.fetchFenceClassMapThenRidersAll();
        }
      );
  }

  mapRiders(rider: RiderExtendedSignalR, fenceClassmap: Map<number, Fence[]>): DataItem<RiderClassFence> {
    return {
      key: rider?.rider.riderId,
      displayname: rider?.rider.horseCombinationNumber.toString(),
      kombinationstypEnum: KombinationstypEnum.must1of1,
      value: mapRiderClassFence(rider, fenceClassmap),
      sortOrder: this.getSortOrder(rider),
      badge: this.mapBadge(rider)
    };
  }

  logoSrc(riderId: string): string {
    return `https://backend1.faxeit.se/clublogotypes/${this.clubLogosMap.has(riderId) ? this.clubLogosMap.get(riderId) : '39'}`;
  }

  rolingTime(riderId: string): string {
    return `https://backend1.faxeit.se/clublogotypes/${this.clubLogosMap.has(riderId) ? this.clubLogosMap.get(riderId) : '39'}`;
  }

  mapBadge(rider: RiderExtendedSignalR): string {
    const sorted = rider
      ?.fenceResults
      .slice()
      ?.sort((a, b) => b.sequence - a.sequence);

    if (sorted.length > 0) {
      return sorted[0].fenceName;
    }
    return undefined;
  }

  getSortOrder(rider: RiderExtendedSignalR): number {
    const sorted = rider
      ?.fenceResults
      .slice()
      ?.sort((a, b) => a.sequence - b.sequence);

    if (sorted?.length > 0) {
      return sorted[0]?.sequence;
    }
    return 0;
  }

  fetchFenceClassMapThenRidersAll(): void {
    this.fenceFacadeService.fetchFenceClassMap(this.componentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(fenceClassMap => {
        this.fenceClassMap = fenceClassMap;
        this.fetchRidersAll();
      });
  }

  mapFence(fence: Fence): DataItem<Fence> {
    return {
      key: fence?.fenceId,
      displayname: fence?.fenceName,
      value: fence,
      sortOrder: fence.sequence
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    clearInterval(this.intervalId);
  }


  handleCollapse($event: boolean, riderId: string) {
    this.riderColapsMap.set(riderId, $event);
  }

  isCollapsed(riderId: string) {
    if (!this.riderColapsMap.has(riderId)) {
      return true;
    }
    return this.riderColapsMap.get(riderId);
  }

  classSelected($event: DataItem<RaceClass>) {
    this.selectedClasses.push($event.value.raceClassId);
    this.selectedClassNameList = this.joinSelectedRaceClasses(this.selectedClasses);
    this.ridersToShow();
  }

  classUnselected($event: DataItem<RaceClass>) {
    this.selectedClasses = this.selectedClasses.filter(rc => rc !== $event.value.raceClassId);
    this.selectedClassNameList = this.joinSelectedRaceClasses(this.selectedClasses);
    this.ridersToShow();
  }

  joinSelectedRaceClasses(selectedClasses: number[]): string {

    if (selectedClasses.find(rc => rc === this.allClassId)) {
      return this.allClassName;
    }

    const selectedClassesO = this.raceClasses
      .filter(rc => selectedClasses.find(src => src === rc.raceClassId));

    const name = selectedClassesO
      .map(rc => rc.raceClassName)
      .join(', ');

    return name.length > 25 ? selectedClassesO[0].raceClassName + ' m.fl.' : name;
  }

  classPreSelected($event: DataItem<RaceClass>[]) {
    $event
      .map(rc => rc.value.raceClassId)
      .forEach(i => this.selectedClasses.push(i));

    this.selectedClassNameList = this.joinSelectedRaceClasses($event.map(i => i.key));
    this.ridersToShow();
  }

  timer() {
    this.intervalId = setInterval(() => {
      this.ridersFilteredToShow?.forEach(r => {
          this.riderTimeMap?.set(r.rider.riderId, raceDuration(r));
      });
    }, 500);
  }

  ridersToShow() {
    this.ridersFilteredToShow = this.filterRiders(this.fetchedRiders);

    this.riderDataItems = this.ridersFilteredToShow
      .sort((n1, n2) => this.timeStringToNumber(n2) - this.timeStringToNumber(n1))
      .map(rider => this.mapRiders(rider, this.fenceClassMap));
  }
}
