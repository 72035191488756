import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import {delay, takeUntil} from 'rxjs/operators';
import {HttpStatusService} from '@app/utils/services/http-status/http-status.service';
import {Observable, of, Subject} from 'rxjs';
import {PageWrapperFacadeService} from '@app/components/utils/page-wrapper/page-wrapper-facade.service';
import {MenuItem} from '@app/components/shared/button-menue/model/menu-item';
import {PageId} from '@app/constants/page-id';
import {Router} from '@angular/router';
import {PasswordService} from '@app/utils/services/password/password.service';

@Component({
  selector: 'faxe-page-wrapper',
  styleUrls: ['./page-wrapper.component.scss'],
  template: `
    <p-sidebar [(visible)]="sidebarVisible" [style]="{width:'15em', }">
      <ng-template pTemplate="header">
        <Span class="faxe-header" >Faxe</Span>
      </ng-template>

      <ng-template pTemplate="content">
        <mat-menu class="meny">
        </mat-menu>
        <div *ngFor="let item of menuItems" class="menu-item">
          <button (click)="buttonClick(item)"
                  mat-menu-item
          >{{item.displayname}}
          </button>
          <button *ngIf="item.iconName" mat-button>
            <mat-icon [svgIcon]="item.iconName" class=menu-icon (click)="iconClick(item)"></mat-icon>
          </button>
        </div>
      </ng-template>

     <!-- <ng-template pTemplate="footer">
         <span
           class="about"
           (click)="onAbout()"
         >
      About
    </span>
      </ng-template>-->
    </p-sidebar>

    <faxe-toolbar *ngIf="!isViewer">
      <ng-container left>
        <mat-icon
          class="ico"
          color="primary"
          svgIcon="more_horiz"
          (click)="sidebarVisible = true"
        ></mat-icon>
      </ng-container>
      <ng-container right *ngIf="showClock"><span class="clock"><b> {{ time | date: 'HH:mm:ss' }}</b></span> </ng-container>
      <ng-container right></ng-container>
      <ng-container right></ng-container>
    </faxe-toolbar>

    <div class="progress-bar">
      <div *ngIf="dialogClosed$ | async">
        <mat-progress-bar
          *ngIf="loading$ | async"
          mode="indeterminate"
        ></mat-progress-bar>
      </div>
    </div>
    <div class="notification-wrapper">
      <faxe-notification-area
        (renderHeight)="onHeight($event)"
      ></faxe-notification-area>
    </div>
    <div
      id="page-content"
      [ngClass]="{
        'content-page-wrapper-no-toolbar': isViewer,
        'content-page-wrapper': true,
        'one-column': !this.numOfCols || this.numOfCols <= 1,
        'two-columns': this.numOfCols === 2,
        'three-columns': this.numOfCols === 3
      }"
    >
      <ng-content></ng-content>
    </div>
    <router-outlet name="dialog"></router-outlet>
  `,
  styles: [
    `
      faxe-page-wrapper {
        display: flow-root;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PageWrapperComponent implements OnInit, OnDestroy {
  @Input() numOfCols: number;
  @Input() showClock: boolean;
  dialogClosed$: Observable<boolean> = of(false);
  loading$: Observable<boolean>;
  height: 0;
  sidebarVisible: boolean;
  mnuFenceResultAudience = 'Tävlingsresultat';
  mnuStarter = 'Start';
  mnuStop = 'Målgång';
  mnuResult = 'Resultat';
  mnuFenseResult = 'Hinderbedömning';
  mnuCreateRace = 'Skapa tävling';

  menuItems: MenuItem<string>[];
  isViewer: boolean;
  time = new Date();
  intervalId: NodeJS.Timeout;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private httpStatusService: HttpStatusService,
    private service: PageWrapperFacadeService,
    private router: Router,
    private passwordService: PasswordService
  ) {
  }

  ngOnInit(): void {
    this.isViewer = this.passwordService.isViewer();
    this.menuItems = this.genereateFunctionMenuItems();
    this.dialogClosed$ = this.service
      .isDialogClosed()
      .pipe(takeUntil(this.unsubscribe$), delay(0));

    this.loading$ = this.httpStatusService.loading$.pipe(
      takeUntil(this.unsubscribe$),
      delay(0)
    );

    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    clearInterval(this.intervalId);
  }

  onHeight($event: number): void {
    this.renderer.setStyle(
      this.elementRef.nativeElement.querySelector('#page-content'),
      'padding-top',
      `${$event}px`
    );
  }

  buttonClick($event: any) {
    this.sidebarVisible = false;
    this.router.navigate([`/${$event.value}/`]);
  }

  iconClick($event: any) {
    this.router.navigate([`/${$event}/`]);
  }

  genereateFunctionMenuItems = (): MenuItem<string>[] => [
    {
      value: PageId.fenceResultAudience,
      iconName: undefined,
      displayname: this.mnuFenceResultAudience,
    },
    {
      value: PageId.start,
      iconName: undefined,
      displayname: this.mnuStarter,
    },
    {
      value: PageId.finish,
      iconName: undefined,
      displayname: this.mnuStop,
    },
    {
      value: PageId.raceResult,
      iconName: undefined,
      displayname: this.mnuResult,
    },
    {
      value: PageId.fenseResult,
      iconName: undefined,
      displayname: this.mnuFenseResult,
    },
    {
      value: PageId.createRace,
      iconName: undefined,
      displayname: this.mnuCreateRace,
    },
  ];

  onAbout() {

  }
}
