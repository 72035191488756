import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectOptionChipComponent } from './select-option-chip.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatIconModule} from '@angular/material/icon';



@NgModule({
  declarations: [SelectOptionChipComponent],
  exports: [
    SelectOptionChipComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule
  ]
})
export class SelectOptionChipModule { }
