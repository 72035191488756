import {createAction, props} from '@ngrx/store';
import {Fence} from '@app/rest-client/faxe/models/fence';

const prefix = '[FAXE-FENCE]';

export const fetchAllFences = createAction(
  `${prefix} - fetch all`,
  props<{ issuerStack: string[] }>()
);

export const getFences = createAction(
  `${prefix} - get`,
  props<{ issuerStack: string[] }>()
);

export const addFence = createAction(
  `${prefix} - add`,
  props<{ model: Fence; issuerStack: string[] }>()
);

export const addManyFences = createAction(
  `${prefix} - add many`,
  props<{ models: Fence[]; issuerStack: string[] }>()
);

export const postFence = createAction(
  `${prefix} - post`,
  props<{ fences: Fence[]; issuerStack: string[] }>()
);

export const removeFence = createAction(
  `${prefix} - remove`,
  props<{ fenceId: string; issuerStack: string[] }>()
);

export const reloadFences = createAction(
  `${prefix} - reload`,
  props<{ issuerStack: string[] }>()
);
