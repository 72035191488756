import {  map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { RootState } from './root-state';
import {
  applicationShowErrorMessage,
} from './root-actions';
import { ErrorMessageUtilService } from '@app/utils/services/error-message/error-message-util.service';

@Injectable()
export class RootEffects {

  applicationShowErrorMessageEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(applicationShowErrorMessage),
        map((action) => {
          this.errorMessageUtilService.showMessage(
            { code: action.payload.error.errorCode?.code },
            action.payload.error.detailErrorCodeList?.map((d) => ({
              code: d.code,
            }))
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private errorMessageUtilService: ErrorMessageUtilService
  ) {}

}
