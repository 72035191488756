import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationAreaComponent } from './notification-area.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { HtmlDisplayModule } from '@app/components/utils/html/html-display.module';

@NgModule({
  declarations: [NotificationAreaComponent, NotificationMessageComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, HtmlDisplayModule, HtmlDisplayModule],
  exports: [NotificationAreaComponent],
})
export class NotificationAreaModule {}
