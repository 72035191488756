import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpStatusService {

  public loadingSub$ = new ReplaySubject<boolean>(1);
  public loading$ = this.loadingSub$.pipe(distinctUntilChanged());

  set loading(val: boolean) {
    this.loadingSub$.next(val);
  }
}
