import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {Observable} from 'rxjs';
import {fetchAllRider, toggleHandled} from '@app/store/faxe/rider-extended/rider-extended.actions';
import {
  riderOnTrack,
  riderRecentlyOnTrack,
  selectRiderExtended
} from '@app/store/faxe/rider-extended/rider-extended.selectos';
import {RiderExtendedSignalR} from '@app/rest-client/faxe/models/rider-extended-signal-r';

@Injectable({
  providedIn: 'root'
})
export class RiderFacadeService {

  constructor(private store: Store<RootState>) {
  }

  fetchRiders(issuer: string, recentlyOnTrack: boolean): Observable<RiderExtendedSignalR[]> {
    this.store.dispatch(
      fetchAllRider(
        {issuerStack: [issuer]}));

    return recentlyOnTrack ? this.selectRidersRecentlyOnTrack() : this.selectRiderExtended();
  }

  fetchRidersOnTrack(issuer: string): Observable<RiderExtendedSignalR[]> {
    this.store.dispatch(
      fetchAllRider(
        {issuerStack: [issuer]}));

    return this.selectRiderExtendedOnTrack();
  }

  toggleHandled(riderId: string, isHandled: boolean, issuer: string) {
    this.store.dispatch(
      toggleHandled(
        {riderId, isHandled, issuerStack: [issuer]}));
  }

  selectRidersRecentlyOnTrack() {
    return this.store.pipe(
      select(riderRecentlyOnTrack()));
  }

  selectRiderExtended() {
    return this.store.pipe(
      select(selectRiderExtended()));
  }

  selectRiderExtendedOnTrack() {
    return this.store.pipe(
      select(riderOnTrack()));
  }
}
