import {createAction, props} from '@ngrx/store';
import {RaceClass} from '@app/rest-client/faxe/models/race-class';

const prefix = '[FAXE-RACE-CLASS]';

export const fetchAllRaceClasses = createAction(
  `${prefix} - fetch all`,
  props<{ issuerStack: string[] }>()
);

export const getRaceClasses = createAction(
  `${prefix} - get`,
  props<{ issuerStack: string[] }>()
);

export const addManyRaceClasses = createAction(
  `${prefix} - add many`,
  props<{ models: RaceClass[]; issuerStack: string[] }>()
);

export const removeAllRaceClasses = createAction(
  `${prefix} - removeAll`,
  props<{ issuerStack: string[] }>()
);

export const reloadRaceClasses = createAction(
  `${prefix} - reload`,
  props<{ issuerStack: string[] }>()
);
