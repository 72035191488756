import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {RootState} from '@app/store/root-state';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isNotEmpty} from '@app/utils/functions/common-functions';
import {ClubLogo} from '@app/rest-client/faxe/models/club-logo';
import {fetchAllClubLogos} from '@app/store/faxe/club-logo/club-logo.actions';
import {selectClubLogo} from '@app/store/faxe/club-logo/club-logo.selectos';

@Injectable({
  providedIn: 'root'
})
export class ClubLogoFacadeService {

  constructor(private store: Store<RootState>) {
  }

  fetchAllClubLogos(issuer: string): Observable<ClubLogo[]> {
    this.store.dispatch(
      fetchAllClubLogos(
        {issuerStack: [issuer]}));

    return this.store.pipe(
      select(selectClubLogo()),
      filter((data) => isNotEmpty(data)));
  }

}
