import { createAction, props } from '@ngrx/store';
import { Message } from './message-state';

export const message = createAction(
  '[MESSAGE]',
  props<{ payload: Message }>()
);
export const messagesConsumed = createAction(
  '[MESSAGE] Consumed',
  props<{ payload: number }>()
);
