import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MenuItem} from '@app/components/shared/button-menue/model/menu-item';

@Component({
  selector: 'faxe-button-menue',
  templateUrl: './button-menue.component.html',
  styleUrls: ['./button-menue.component.scss']
})
export class ButtonMenueComponent<T> {

  @Input() iconName: string;
  @Input() menueName: string;
  @Input() menuItems: MenuItem<T>[];
  @Output() itemClick: EventEmitter<T> = new EventEmitter<T>();
  @Output() itemIconClick: EventEmitter<MenuItem<T>> = new EventEmitter<MenuItem<T>>();

  iconClick(item: MenuItem<T>): void {
    this.itemIconClick.emit(item);
  }

  buttonClick(item: MenuItem<T>): void {
    this.itemClick.emit(item.value);
  }
}
