<ng-container *ngFor="let item of lista; let i = index">
  <ng-container *ngIf="i < antalAttVisa && !visaAlla">
    {{ item }}{{last(item)? '' : ', '}} <br *ngIf="break && !horizontal" />
  </ng-container>
  <ng-container *ngIf="visaAlla">
    {{ item }}{{last(item)? '' : ', '}} <br *ngIf="(!last(item) || break) && !horizontal" />
  </ng-container>
</ng-container>
<div
  [ngClass]="{ toggle: true, pad: !break }"
  (click)="toggle()"
  *ngIf="lista?.length > antalAttVisa"
>
  <ng-container   *ngIf="visaAlla">
    <span class="link">{{ toggleLessText }}</span>
  </ng-container>

  <ng-container *ngIf="!visaAlla">
    <span class="link">{{ toggleMoreText }}</span>
  </ng-container>
</div>
<em *ngIf="lista?.length === 0">{{ listEmptyText }}</em>
