<button
  color="primary"
  mat-button
  [matMenuTriggerFor]="registreraMeny">
  <mat-icon [svgIcon]="iconName"></mat-icon>
  {{menueName}}
</button>
<mat-menu #registreraMeny="matMenu" class="meny">
  <div *ngFor="let item of menuItems" class="menu-item">
    <button (click)="buttonClick(item)"
            mat-menu-item
    >{{item.displayname}}
    </button>
    <button *ngIf="item.iconName" mat-button>
      <mat-icon [svgIcon]="item.iconName" class=menu-icon (click)="iconClick(item)"></mat-icon>
    </button>
  </div>
</mat-menu>
