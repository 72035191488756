import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ExpandedTableRowDirective } from './expanded-table-row.directive';

@NgModule({
  declarations: [ExpandedTableRowDirective],
  imports: [CommonModule],
  exports: [ExpandedTableRowDirective],
})
export class ExpandedTableRowDirectiveModule {}
