import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { MessageStoreState } from './message-state';
import { MessageShowInPresentationStateEnum } from './message.enum';

export const getMessagesStoreState: MemoizedSelector<
  // eslint-disable-next-line @typescript-eslint/ban-types
  object,
  MessageStoreState
> = createFeatureSelector<MessageStoreState>('messages');

export const getDialogMessages = createSelector(
  getMessagesStoreState,
  (saData) => saData.data
      .filter((m) => (
          m.showInPresentationState ===
            MessageShowInPresentationStateEnum.whatEver ||
          m.showInPresentationState ===
            MessageShowInPresentationStateEnum.dialog
        ))
      .pop()
);

export const getPageMessages = createSelector(
  getMessagesStoreState,
  (saData) => saData.data
      .filter((m) => (
          m.showInPresentationState ===
            MessageShowInPresentationStateEnum.whatEver ||
          m.showInPresentationState === MessageShowInPresentationStateEnum.page
        ))
      .pop()
);
