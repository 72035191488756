import { Injectable } from '@angular/core';
import {SessionKeys} from '@app/constants/session-keys';


@Injectable({
  providedIn: 'root',
})
export class ReloadRouteUtilityService {


  constructor() {
    }

  /**
   * When route guard runs on a route, test to see if current URL is a outlet url or not
   * If it isn't, store the URL in sessionStorage
   */
  public storeCurrentUrlInSessionStorage(currUrl: string): void {
    const urlInOutletRegex = /\/\((\w+:\S*)\)/g;
    if (!currUrl.match(urlInOutletRegex)) {
      sessionStorage.setItem(SessionKeys.navigateback, currUrl);
    }
  }

  public resetCurrentUrlInSessionStorage(): void {
    sessionStorage.setItem(SessionKeys.navigateback, '');
  }

  public getCurrentUrlInSessionStorage(): string {
    const storedUrl = sessionStorage.getItem(SessionKeys.navigateback);
    return storedUrl ? storedUrl : '/';
  }
}
