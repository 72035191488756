import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FenceResultAudienceComponent } from './fence-result-audience.component';
import {MatCardModule} from '@angular/material/card';
import {ShowMoreModule} from '@app/components/shared/show-more/show-more.module';
import {CollapisbleSectionModule} from '@app/components/shared/collapisble-section/collapisble-section.module';
import {TableModule} from '@app/components/shared/table/table.module';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from "@angular/material/list";
import {MatExpansionModule} from "@angular/material/expansion";
import {SelectOptionChipModule} from "@app/components/shared/select-option-chip/select-option-chip.module";
import {ChipListModule} from "@app/components/shared/chip-list/chip-list.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";



@NgModule({
  declarations: [
    FenceResultAudienceComponent
  ],
  exports: [
    FenceResultAudienceComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        ShowMoreModule,
        CollapisbleSectionModule,
        TableModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        SelectOptionChipModule,
        ChipListModule,
        MatProgressBarModule,
    ]
})
export class FenceResultAudienceModule { }
