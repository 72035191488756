<header>
  <div class="navbar grid">
    <div class="left-content">
      <ng-content select="[left]"></ng-content>
    </div>
    <div class="center-content">
      <ng-content select="[center]"></ng-content>
    </div>
    <div class="right-content">
      <ng-content select="[right]"></ng-content>
    </div>
  </div>
</header>
<mat-divider class="divider"></mat-divider>
