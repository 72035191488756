import { DialogNameEnums, DialogStoreEnums } from './dialog-store.enums';
import {ErrorMessageCodeEnum} from '@app/utils/services/error-message/error-message-code.enum';

export interface DialogStoreState {
  readonly status: string;
  readonly routeParam: number;
  readonly dialogNamn?: DialogNameEnums;
  readonly navigate?: boolean;
  readonly dialogOppnad?: boolean;
  readonly correlationId?: string;
  readonly errorMessageCodeEnum?: ErrorMessageCodeEnum;
}

export const initialDialogStoreState = {
  status: DialogStoreEnums.oanvand,
  routeParam: null,
  dialogNamn: null,
  navigate: false,
  dialogOppnad: false,
  correlationId: null,
  errorMessageCodeEnum: null,
} as DialogStoreState;
