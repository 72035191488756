import {Component, OnInit} from '@angular/core';
import {IconRegisterService} from '@app/utils/services/icon-register/icon-register.service';
import {SignalRService} from '@app/services/signal-r.service';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs';
import {LocalUrls} from '@assets/contract-faxe/local-urls';
import {AppConfigService} from '@app/utils/services/config/app-config.service';
import {DeploymentConfigData} from '@app/utils/services/config/model/deployment-config-data';

@Component({
  selector: 'faxe-root',
  template: `
    <faxe-header></faxe-header>
    <router-outlet></router-outlet>
  `,
})

export class AppComponent implements OnInit {
  title = 'faxe';

  constructor(
    private iconService: IconRegisterService,
    private readonly signalRService: SignalRService,
    private httpClient: HttpClient,
  ) {
    console.log('faxe autentication not used. Should start here configureAndLoadDiscoveryDocument');
  }

  ngOnInit(): void {
    this.iconService.registerIcons();
    this.signalRService.init();

    this.httpClient.get(LocalUrls.deployment)
      .pipe(take(1))
      .subscribe(file => {
        AppConfigService.initDeployment((file as DeploymentConfigData));
      });

    this.readAndStoreQueryVariable();
  }

  readAndStoreQueryVariable() {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    vars.map(v => v.split('='))
      .map(pair => sessionStorage.setItem(pair[0], pair[1]));
  }

}
