import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {ChipListComponent} from '@app/components/shared/chip-list/chip-list.component';
import {MatBadgeModule} from '@angular/material/badge';

@NgModule({
  declarations: [ChipListComponent],
    imports: [
        CommonModule,
        MatChipsModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatBadgeModule,
    ],
  exports: [ChipListComponent],
})
export class ChipListModule {}
