import {
  DialogStoreState,
  initialDialogStoreState,
} from './dialog-store-state';
import {DialogStoreEnums} from './dialog-store.enums';
import {createReducer, on, Action} from '@ngrx/store';
import {
  initDialog,
  operationMisslyckades,
  setDialogOppnad,
  setDialogStangd,
  stangDialog,
} from './dialog-store-actions';

const featureReducer = createReducer(
  initialDialogStoreState,
  on(initDialog, () => ({
    status: DialogStoreEnums.oanvand,
    routeParam: null,
  })),
  on(operationMisslyckades, (state, {payload}) => ({
    status: DialogStoreEnums.operationmisslyckades,
    routeParam: null,
    dialogOppnad: state.dialogOppnad,
    correlationId: payload?.correlationId,
    errorMessageCodeEnum: payload.errorMessageCodeEnum
  })),
  on(setDialogStangd, () => ({
    status: DialogStoreEnums.oanvand,
    routeParam: null,
    dialogOppnad: false,
  })),
  on(setDialogOppnad, () => ({
    status: DialogStoreEnums.oanvand,
    routeParam: null,
    dialogOppnad: true,
  })),
  on(stangDialog, (_, {payload}) => ({
    status: DialogStoreEnums.stangdialog,
    routeParam: null,
    dialogNamn: null,
    navigate: payload?.navigate,
    correlationId: payload?.correlationId,
  }))
);

export const dialogStoreReducer = (
  state: DialogStoreState,
  action: Action
): DialogStoreState => featureReducer(!state ? initialDialogStoreState : state, action);
