// lite hela poängen...
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const typeOf = <T>(object: any, diskriminator: string): object is T => object ? diskriminator in object : false;


export const isNotEmpty = <T>(list: T[]): boolean => list && list.length > 0;

export const isEmpty = <T>(list: T[]): boolean => !list || list.length < 1;

export const push = <T>(list: T[], t: T): T[] => {
  const slice = list.slice();
  slice.push(t);
  return slice;
};

export const distict = <T>(list: T[], key: string): T[] => [...new Map(list.map(item => [item[key], item])).values()];

export const padLeft = (text: string, padChar: string, size: number): string =>
  (String(padChar).repeat(size) + text).substr((size * -1), size);

export const groupBy = <T, Y>(list: T[], f: (t: T) => Y, s: (t: T) => number): Map<Y, T[]> => {
  const map: Map<Y, T[]> = new Map<Y, T[]>();

  list.forEach(l => {
    const value = map.get(f(l));
    if (!value) {
      map.set(f(l), [l]);
    } else {
      value.push(l);
      value.sort((a,b) => s(a) - s(b));
    }
  });
  return map;
};

