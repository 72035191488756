/*
 * Används för att beordra en dialog att stängas
 */
export enum DialogStoreEnums {
  stangdialog = 'STÄNG_DIALOG',
  operationmisslyckades = 'OPERATION_MISSLYCKADES',
  oanvand = 'OANVAND',
}

/* namn på dialoger*/
export enum DialogNameEnums {
  s = 'STÄLLNINGSTAGANDE_DIALOG',
}
