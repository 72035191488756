import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {addManyRider, removeAll, upsetOne} from '@app/store/faxe/rider-extended/rider-extended.actions';
import {RiderExtendedSignalR} from '@app/rest-client/faxe/models/rider-extended-signal-r';

export const identifier = (a: RiderExtendedSignalR): string => a.rider.riderId;

export const adapter: EntityAdapter<RiderExtendedSignalR> = createEntityAdapter<RiderExtendedSignalR>({
  selectId: identifier,
});

export type RiderExtendedEntityState = EntityState<RiderExtendedSignalR>;

export const initialState: RiderExtendedEntityState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(removeAll, (state) => adapter.removeAll(state)),
  on(addManyRider, (state, {models}) => adapter.addMany(models, state)),
  on(upsetOne, (state, {model}) => adapter.upsertOne(model, state)),
);

export const riderExtendedReducer: (state: (RiderExtendedEntityState | undefined), action: Action) =>
  EntityState<RiderExtendedSignalR> = (state: RiderExtendedEntityState | undefined, action: Action) =>
  reducer(state, action);

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllRider = selectAll;


