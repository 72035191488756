import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {faxeReducers} from '@app/store/faxe/faxe-store-model';
import {FenceResultEffects} from '@app/store/faxe/fence-result/fence-result.effects';
import {FenceEffects} from '@app/store/faxe/fences/fence.effects';
import {ResultCodeEffects} from '@app/store/faxe/results-code/result-code.effects';
import {RiderExtendedEffects} from '@app/store/faxe/rider-extended/rider-extended.effects';
import {RaceClassEffects} from '@app/store/faxe/race-class/race-class.effects';
import {ClubLogoEffects} from "@app/store/faxe/club-logo/club-logo.effects";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature('faxe', faxeReducers),
    EffectsModule.forFeature([
      RiderExtendedEffects,
      FenceResultEffects,
      FenceEffects,
      ResultCodeEffects,
      RiderExtendedEffects,
      RaceClassEffects,
      ClubLogoEffects,
    ]),
  ],
})
export class FaxeStoreModule {}
