import { Injectable } from '@angular/core';
import { RootState } from '@app/store/root-state';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {isDialogClosed} from '@app/store/client-slices/dialog/dialog-store-selectors';

@Injectable({
  providedIn: 'root',
})
export class PageWrapperFacadeService {
  constructor(private store: Store<RootState>) {}

  isDialogClosed(): Observable<boolean> {
    return this.store.pipe(select(isDialogClosed));
  }
}
