import { Component, Input } from '@angular/core';

@Component({
  selector: 'faxe-html-display',
  templateUrl: './html-display.component.html',
  styleUrls: ['./html-display.componet.scss'],
})
export class HtmlDisplayComponent {
  @Input() htmlString: string;
  @Input() newline: boolean;
}
