export enum MultiMessageEnum {
  riderExtendedSignalR = 'HorseCommon.Model.RiderExtendedSignalR',
  startedRider = 'HorseCommon.Model.StartedRider',
  riderEvent = 'HorseCommon.Model.RiderEvent',
  rider = 'HorseCommon.Model.Rider',
  resultCode = 'HorseCommon.Model.ResultCode',
  raceClass = 'HorseCommon.Model.RaceClass',
  race = 'HorseCommon.Model.Race',
  fenceResult = 'HorseCommon.Model.FenceResult',
  fence = 'HorseCommon.Model.Fence',
}
