import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, mergeMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EMPTY, of,} from 'rxjs';
import {isNotEmpty, push} from '@app/utils/functions/common-functions';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {onOperationStatus} from '@app/store/client-slices/operation/operation.actions';
import {addManyRaceClasses, fetchAllRaceClasses, getRaceClasses} from '@app/store/faxe/race-class/race-class.actions';
import {selectRaceClassAll} from '@app/store/faxe/race-class/race-class.selectos';
import {RaceClassService} from '@app/rest-client/faxe/services/race-class.service';

@Injectable()
export class RaceClassEffects {

  fetchRaceClassEffect = createEffect(() => this.actions$.pipe(
    ofType(fetchAllRaceClasses),
    withLatestFrom(this.store.select(selectRaceClassAll)),
    exhaustMap(([action, state]) => {

      if (isNotEmpty(state)) {
        return EMPTY;
      }

      const actions = [];
      actions.push(getRaceClasses({
        issuerStack: push(action.issuerStack,'fetchRaceClassEffect')
      }));
      return of(...actions);
    }),
    catchError(() => EMPTY)
    )
  );

  getRaceClassEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(getRaceClasses),
      exhaustMap((action) => this.service
        .getRaceClasses()
        .pipe(mergeMap((response) => {
            const actions = [];
            actions.push(addManyRaceClasses(
              {models: response, issuerStack: push(action.issuerStack,'getRaceClassEffect')}));
            return of(...actions);
          }),
          catchError((e) =>
            of(onOperationStatus({model: {
                status: e.status,
                operationId:'getRaceClassEffect',
                callStack: push(action.issuerStack, 'getRaceClassEffect')}}))
          )
        )),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private service: RaceClassService,
    private actions$: Actions,
    private store: Store<FaxeStore>,
  ) {
  }
}
