import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, mergeMap, withLatestFrom} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {EMPTY, of,} from 'rxjs';
import {isNotEmpty, push} from '@app/utils/functions/common-functions';
import {FaxeStore} from '@app/store/faxe/faxe-store-model';
import {addManyFences, fetchAllFences, getFences} from '@app/store/faxe/fences/fence.actions';
import {selectFenceAll} from '@app/store/faxe/fences/fence.selectos';
import {FencesService} from '@app/rest-client/faxe/services/fences.service';
import {onOperationStatus} from '@app/store/client-slices/operation/operation.actions';

@Injectable()
export class FenceEffects {

  fetchFenceEffect = createEffect(() => this.actions$.pipe(
    ofType(fetchAllFences),
    withLatestFrom(this.store.select(selectFenceAll)),
    exhaustMap(([action, state]) => {

      if (isNotEmpty(state)) {
        return EMPTY;
      }

      const actions = [];
      actions.push(getFences({
        issuerStack: push(action.issuerStack,'fetchFenceEffect')
      }));
      return of(...actions);
    }),
    catchError(() => EMPTY)
    )
  );

  getFenceEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(getFences),
      exhaustMap((action) => this.service
        .fences()
        .pipe(mergeMap((response) => {
            const actions = [];
            actions.push(addManyFences(
              {models: response, issuerStack: push(action.issuerStack,'getFenceEffect')}));
            return of(...actions);
          }),
          catchError((e) =>
            of(onOperationStatus({model: {
                status: e.status,
                operationId:'getFenceEffect',
                callStack: push(action.issuerStack, 'getFenceEffect')}}))
          )
        )),
      catchError(() => EMPTY)
    )
  );

  constructor(
    private service: FencesService,
    private actions$: Actions,
    private store: Store<FaxeStore>,
  ) {
  }
}
