import {createSelector, MemoizedSelector} from '@ngrx/store';
import {getClientSlicesStore} from '@app/store/client-slices/client-slices-selectors';

export const getOperationStoreState = createSelector(
  getClientSlicesStore,
  (clientStore) => clientStore.operation
);

export const getOperationStoreStatus = createSelector(
  getOperationStoreState,
  (operationData) => operationData
);

export const selectOperationStatus = (correlationId: string):
  // eslint-disable-next-line @typescript-eslint/ban-types
  MemoizedSelector<object, boolean> =>
  createSelector(
    getOperationStoreState,
    (operationStatus) =>
      operationStatus.correlationId === correlationId
  );




