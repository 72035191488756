import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {Fence} from '@app/rest-client/faxe/models/fence';
import {addFence, addManyFences, removeFence} from '@app/store/faxe/fences/fence.actions';

export const identifier = (a: Fence): string => a.fenceId;

export const adapter: EntityAdapter<Fence> = createEntityAdapter<Fence>({
  selectId: identifier,
});

export type FenceEntityState = EntityState<Fence>;

export const initialState: FenceEntityState = adapter.getInitialState({
  // additional entity state properties
});

const reducer = createReducer(
  initialState,
  on(addFence, (state, {model}) => adapter.addOne(model, state)),
  on(addManyFences, (state, {models}) => adapter.addMany(models, state)),
  on(removeFence, (state, {fenceId}) => adapter.removeOne(fenceId, state)),
);

export const fenceReducer: (state: (FenceEntityState | undefined), action: Action) =>
  EntityState<Fence> = (state: FenceEntityState | undefined, action: Action) =>
  reducer(state, action);

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllFence = selectAll;


