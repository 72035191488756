import { UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';


export declare type ValidatorValuesFn<T> = (values: T[]) => ValidationErrors | null;
export declare type ValidatorValueFn<T> = (value: T) => ValidationErrors | null;

export const invokeValues =
  <T>(validatorValuesFn: ValidatorValuesFn<T>,formGroup: UntypedFormGroup, valueParser: (v: UntypedFormGroup) => T):
  ValidatorFn => (): ValidationErrors | null => validatorValuesFn.call(this,valueParser(formGroup));

export const invokeValue = <T>(validatorValueFn: ValidatorValueFn<T>,formGroup: UntypedFormGroup, valueParser: (v: UntypedFormGroup) => T):
  ValidatorFn => (): ValidationErrors | null => validatorValueFn.call(this,valueParser(formGroup));
